import {memo, useEffect, useState} from "react";
import {
    SearchPanelBaseContainer,
    SearchPanelCol,
    SearchPanelFormButton,
    SearchPanelFormInput,
    SearchPanelInputGroup,

} from "./styles";
import "./SearchPanelBase.sass"
import * as React from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {setCatalogSearchValue} from "../../store/slices/PagePaginagionSlice";
import {useLocation} from "react-router-dom";
import {EMPTY_STRING, SEARCH_PARAMS} from "../../constants/consts";
import {CatalogControl} from "../catalog/catalogControl/CatalogControl";
import {t} from "i18next";
import {FilterItemsType} from "../catalog/types";

interface SearchPanelBaseProps {
    initialSearchValue?: string
    searchPageParams?: FilterItemsType
}

export const SearchPanelBase = memo((props: SearchPanelBaseProps) => {

    const location = useLocation();
    const {searchPageParams, initialSearchValue} = props

    const [inputValue, setIntutValue] = useState<any>(initialSearchValue || EMPTY_STRING);
    const [placeholder, setPlaceholder] = useState("")
    const {activeFilters, searchValue} = useAppSelector(state => state.page)

    let searchParams = searchPageParams || SEARCH_PARAMS

    useEffect(() => {
        setIntutValue(inputValue)
        dispatch(setCatalogSearchValue(inputValue))
    }, [location]);

    useEffect(() => {
        setPlaceholder(activeFilters.map(item => t(searchParams[item]) ).join(' / '))
    }, [activeFilters]);

    useEffect(() => {
        if (!searchValue || searchValue === "") {
            setIntutValue(searchValue)
        }
    }, [searchValue]);


    const handleOnChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIntutValue(event.target.value)
    }

    const dispatch = useAppDispatch()

    const handleOnClickSearch = (event: React.FormEvent) => {
        dispatch(setCatalogSearchValue(inputValue))
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            dispatch(setCatalogSearchValue(inputValue))
        }
    }

    return (
        <SearchPanelBaseContainer>
            <SearchPanelCol>
                <SearchPanelInputGroup className="input-group search-panel">
                    <SearchPanelFormButton type="submit" className="btn favIcon" onClick={handleOnClickSearch}>
                        <i className="bi bi-search"></i>
                    </SearchPanelFormButton>
                    <SearchPanelFormInput onKeyDown={handleKeyPress} value={inputValue} onChange={handleOnChangeSearch} type="text" className="form-control" placeholder={placeholder}/>
                    <CatalogControl
                        isSetDisplay={false}
                        isDownload={false}
                        isSetConfig={!searchPageParams}
                        className={"search-panel__config"}
                        btnClassName={"search-panel__btn btn border-0"}
                    />
                </SearchPanelInputGroup>
            </SearchPanelCol>
        </SearchPanelBaseContainer>
    )
})