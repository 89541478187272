import {useAppSelector} from "../../hooks/ReduxHooks";
import "./FinTerms.sass"
import {t} from "i18next";
import {FormattedValue} from "../formattedValue/FormattedValue";

export const FinTerms = () => {

    const {terms} = useAppSelector(state => state.finTerms)

    if (!terms) return <></>

    return (
        <div className="terms__wrapper">
            <div style={{width: "100%", maxWidth: "500px"}}>
                <section className="terms__section">
                    <h3 className="terms__name">{t('credit_limit')}</h3>
                    <ul>
                        <li className="terms__option"><p>{t('credit_limit_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.partner_credit_limit}/> <i
                            className="bi bi-currency-euro"></i></p></li>
                        <li className="terms__option"><p>{t('credit_limit_days_label')}:</p><p
                            className="terms__field">{terms.partner_credit_limit_days} <i
                            className="bi bi-currency-euro"></i></p></li>
                        <li className="terms__option"><p>{t('available_credit_limit_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.available_credit_limit}/><i
                            className="bi bi-currency-euro"></i></p></li>
                        <li className="terms__option"><p>{t('used_credit_limit_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.used_credit_limit}/><i className="bi bi-currency-euro"></i>
                        </p></li>
                    </ul>
                </section>
                <section className="terms__section">
                    <h3 className="terms__name">{t('reservations')}</h3>
                    <ul>
                        <li className="terms__option"><p>{t('reservation_limit_days_label')}:</p><p
                            className="terms__field">{terms.reservation_limit_day}</p></li>
                        <li className="terms__option"><p>{t('reservation_limit_cash_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.reservation_limit_cash}/><i
                            className="bi bi-currency-euro"></i></p></li>
                    </ul>
                </section>
                <section className="terms__section">
                    <h3 className="terms__name">{t('orders')}</h3>
                    <ul>
                        <li className="terms__option"><p>{t('posted_orders_sum_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.posted_orders_sum}/><i className="bi bi-currency-euro"></i>
                        </p></li>
                        <li className="terms__option"><p>{t('delayed_orders_sum_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.delayed_orders_sum}/><i className="bi bi-currency-euro"></i>
                        </p></li>
                    </ul>
                </section>
            </div>
            <div style={{width: "100%", maxWidth: "500px"}}>
                <section className="terms__section">
                    <h3 className="terms__name">{t('invoice_payment')}</h3>
                    <ul>
                        <li className="terms__option"><p>{t('invoice_total_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.invoice_total}/><i className="bi bi-currency-euro"></i></p>
                        </li>
                        <li className="terms__option"><p>{t('invoice_total_overdue_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.invoice_total_overdue}/><i
                            className="bi bi-currency-euro"></i></p></li>
                        <li className="terms__option"><p>{t('invoice_payment_terms_label')}:</p><p
                            className="terms__field"></p></li>
                        <li className="terms__option"><p>{t('penalties_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.penalties * 100} accuracy={0}/>%</p></li>
                        <li className="terms__option"><p>{t('unpaid_invoices_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.unpaid_credit_invoice}/><i
                            className="bi bi-currency-euro"></i></p></li>
                        <li className="terms__option"><p>{t('credit_invoices_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.credit_invoice_total}/><i className="bi bi-currency-euro"></i>
                        </p></li>
                        <li className="terms__option"><p>{t('overpayment_label')}:</p><p
                            className="terms__field"><FormattedValue value={terms.overpayment}/></p></li>
                        <li className="terms__option"><p>{t('historical_data_label')}:</p><p
                            className="terms__field">{terms.historical_data}<i className="bi bi-currency-euro"></i></p>
                        </li>
                    </ul>
                </section>
            </div>
        </div>

    )
}