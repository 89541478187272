import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {fetchOrders, resetOrders} from "../../store/slices/OrdersReducerSlice";
import {OrdersContent} from "../../components/orders/ordersContent/OrdersContent";
import {useLocation} from "react-router-dom";
import {ORDER_TYPES, SEARCH_ORDER_LINES_PARAMS, SEARCH_ORDERS_PARAMS} from "../../constants/consts";
import {TabItem} from "../../components/tabs/TabItem";
import {TabList} from "../../components/tabs/TabList";
import {fetchLines, resetLines} from "../../store/slices/ReservationLinesReducerSlice";
import {setActiveFilters, setNonStoredFilters, updatePage} from "../../store/slices/PagePaginagionSlice";
import {t} from "i18next";
import {Blured} from "../../components/blured/Blured";
import {SearchPanelBase} from "../../components/searchPanel/SearchPanelBase";
import {Checkbox, FormControlLabel, styled} from "@mui/material";
import {OrderLinesContent} from "../../components/orders/ordersContent/OrderLinesContent";

export type OrderRequestParams = {
    pageNum: number;
    searchValue?: string;
    activeFilters: string[];
    isReserve?: boolean;
    isPreOrder?: boolean;
    isOwn?: boolean;
}

export type OrderLinesRequestParams = {
    pageNum: number;
    isReserve: boolean,
    isPreOrder: boolean,
    isOwn?: boolean;
    searchValue?: string;
    activeFilters?: string[];
}

export const StyledFormControlLabel = styled(FormControlLabel)({
    label: {
        fontSize: "16px",
        color: "gray",
    },
});

export const StyledCheckbox = styled(Checkbox)({
    color: "rgb(110, 209, 243)",
    "&.Mui-checked": {
        color: "rgb(110, 209, 243)",
    },
});

export const OrdersPage = () => {

    const {isOrdersLoading} = useAppSelector(state => state.orders)
    const {isLinesLoading} = useAppSelector(state => state.reservationLines)
    const dispatch = useAppDispatch()
    const {page, searchValue} = useAppSelector(state => state.page)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [orderType, setOrderType] = useState<ORDER_TYPES>(ORDER_TYPES.RESERVE);
    const location = useLocation()
    const [onlyMyDocuments, setOnlyMyDocuments] = useState<boolean>(true);


    const fetchPageOrders = () => {
        let requestData: OrderRequestParams = {
            pageNum: page,
            activeFilters: [],

        };
        if (onlyMyDocuments) {
            requestData["isOwn"] = true
        }
        if (searchValue) {
            requestData["activeFilters"] = Object.values(SEARCH_ORDERS_PARAMS)
            requestData["searchValue"] = searchValue
        }
        if (orderType === ORDER_TYPES.ORDER) {
            requestData["isReserve"] = false
            requestData["isPreOrder"] = false
        }
        if (orderType === ORDER_TYPES.RESERVE) {
            requestData["isReserve"] = true
            requestData["isPreOrder"] = false
        }
        if (orderType === ORDER_TYPES.PRE_ORDER) {
            requestData["isPreOrder"] = true
            requestData["isReserve"] = false
        }
        dispatch(fetchOrders(requestData))
    }

    const fetchOrdersLines = () => {
        let requestData: OrderLinesRequestParams = {
            pageNum: page,
            isReserve: orderType === ORDER_TYPES.RESERVE,
            isPreOrder: orderType === ORDER_TYPES.PRE_ORDER,
        };
        if (onlyMyDocuments) {
            requestData["isOwn"] = true
        }
        if (searchValue) {
            requestData["activeFilters"] = Object.values(SEARCH_ORDER_LINES_PARAMS)
            requestData["searchValue"] = searchValue
        }
        dispatch(fetchLines(requestData))
    }

    useEffect(() => {
        dispatch(resetLines())
        dispatch(resetOrders())
        dispatch(updatePage(1))
    }, [orderType]);


    useEffect(() => {
        if (page === 0 || !orderType) return
        if ([ORDER_TYPES.RESERVE, ORDER_TYPES.PRE_ORDER].includes(orderType)) {
            fetchOrdersLines()
        } else {
            fetchPageOrders()
        }
    }, [page, searchValue, location, orderType, onlyMyDocuments]);


    useEffect(() => {
        if (orderType === ORDER_TYPES.RESERVE) {
            setIsLoading(isLinesLoading)
        } else {
            setIsLoading(isOrdersLoading)
        }
    }, [isOrdersLoading, isLinesLoading, orderType]);


    useEffect(() => {
        dispatch(setNonStoredFilters(Object.keys(SEARCH_ORDERS_PARAMS)))
        return () => {
            dispatch(updatePage(1))
            dispatch(resetOrders())
            dispatch(resetLines())
            dispatch(setActiveFilters(false))
        }
    }, []);

    const getActiveTabIndex = (orderType: ORDER_TYPES) => {
        switch (orderType) {
            case ORDER_TYPES.ORDER: return 2
            case ORDER_TYPES.RESERVE: return 0
            case ORDER_TYPES.PRE_ORDER: return 1
        }
    }


    return (
        <>
            <div className="wrapper" style={{height: "100%"}}>
                <div className={"w-75 mb-4 m-auto"}>
                    <SearchPanelBase
                        initialSearchValue={location?.state?.searchValue}
                        searchPageParams={SEARCH_ORDERS_PARAMS}
                    />
                </div>
                <TabList className="h-100 m-auto" activeTabIndex={getActiveTabIndex(orderType)}>
                    <TabItem label={t('reserve_label')}>
                        <Blured
                            element={
                            <OrderLinesContent
                                type={ORDER_TYPES.RESERVE}
                                setType={setOrderType}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                fetchOrdersLines={fetchOrdersLines}
                                onlyMyDocuments={onlyMyDocuments}
                                setOnlyMyDocuments={setOnlyMyDocuments}
                            />
                            }
                            isLoading={isLoading}
                        />
                    </TabItem>
                    <TabItem className="h-100" label={t('preorder_label')}>
                        <Blured
                            element={
                            <OrderLinesContent
                                type={ORDER_TYPES.PRE_ORDER}
                                setType={setOrderType}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                fetchOrdersLines={fetchOrdersLines}
                                onlyMyDocuments={onlyMyDocuments}
                                setOnlyMyDocuments={setOnlyMyDocuments}
                            />
                            }
                            isLoading={isLoading}
                        />
                    </TabItem>
                    <TabItem className="h-100" label={t('order_label')}>
                        <div style={{marginBottom: "10px"}}>
                            <StyledFormControlLabel
                                control={
                                    <StyledCheckbox
                                        checked={onlyMyDocuments}
                                        onChange={(e) => setOnlyMyDocuments(e.target.checked)}
                                    />
                                }
                                disabled={isLoading}
                                label={t("only_my_documents")}
                            />
                        </div>
                        <Blured
                            element={<OrdersContent type={ORDER_TYPES.ORDER} setType={setOrderType}/>}
                            isLoading={isLoading}
                        />
                    </TabItem>
                </TabList>
            </div>
        </>
    )
}