import React from "react";
import "./ReturnTable.sass"
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {setLineToReturn} from "../../../store/slices/NewReturnSlice";
import {PRODUCT_CONDITION} from "../../../constants/consts";
import {t} from "i18next";
import {FormattedValue} from "../../formattedValue/FormattedValue";

interface TableRowProps {
    lineData: any,
}

export const ReturnTableRow = (props: TableRowProps) => {

    const {lineData} = props
    const {linesToReturn} = useAppSelector(state => state.newReturn)
    const dispatch = useAppDispatch()

    const handleDeleteRow = () => {
        dispatch(setLineToReturn(linesToReturn.filter(el => el.id !== lineData.id)))
    }

    return (
        <tr>
            <td scope="text-start">{lineData.lineData.order_name}</td>
            <td scope="text-start">{lineData.lineData.product_code}</td>
            <td scope="text-start"></td>
            <td scope="text-start">{lineData.lineData.product_name}</td>
            <td scope="text-start"></td>
            <td scope="text-start"></td>
            <td scope="text-start">{lineData.quantity}</td>
            <td scope="text-start"><FormattedValue value={lineData.lineData.price_unit}/> {lineData?.lineData?.currency}</td>
            <td scope="text-start"><FormattedValue value={lineData.lineData.price_subtotal}/> {lineData?.lineData?.currency}</td>
            <td scope="text-start">{PRODUCT_CONDITION[lineData.condition]}</td>
            <td scope="text-start">{lineData.description}</td>
            <td scope="text-start">{lineData?.filesData?.length}</td>
            <td scope="text-start"></td>
            <th scope="col" className="">
                <button type="button" className="btn btn-sm" onClick={handleDeleteRow}>
                    <i className="bi bi-trash"/>
                </button>
            </th>
        </tr>
    )

}

export const ReturnTable = () => {

    const {linesToReturn} = useAppSelector(state => state.newReturn)


    return (
        <>
            <>
                <table className="table returnTable">
                    <thead className="returnTable">
                    <tr>
                        <th scope="col"
                            className="text-secondary text-start returnTable__number">{t('order_number')}</th>
                        <th scope="col"
                            className="text-secondary text-start returnTable__code">{t('product_code')}</th>
                        <th scope="col"
                            className="text-secondary text-start returnTable__man_code">{t('manufacturer_code')}</th>
                        <th scope="col"
                            className="text-secondary text-start returnTable__name">{t('product_name')}</th>
                        <th scope="col"
                            className="text-secondary text-start returnTable__delivery">{t('invoice')}</th>
                        <th scope="col"
                            className="text-secondary text-start returnTable__bill_date">{t('invoice_date')}</th>
                        <th scope="col" className="text-secondary text-start returnTable__qty">{t('quantity')}</th>
                        <th scope="col"
                            className="text-secondary text-start returnTable__price">{t('price_without_vat')}</th>
                        <th scope="col"
                            className="text-secondary text-start returnTable__total">{t('total_without_vat')}</th>
                        <th scope="col"
                            className="text-secondary text-start returnTable__state">{t('product_condition')}</th>
                        <th scope="col" className="text-secondary text-start returnTable__desc">{t('notes')}</th>
                        <th scope="col" className="text-secondary text-start returnTable__desc"></th>
                        <th scope="col" className="text-secondary text-start returnTable__img">{t('photo')}</th>
                        <th></th>


                    </tr>
                    </thead>
                    {linesToReturn && (
                        linesToReturn.map((el) => {
                            return (
                                <>
                                    <tbody className="returnTable__body">
                                    <ReturnTableRow
                                        lineData={el}
                                    />
                                    </tbody>
                                </>
                            )
                        }))}

                </table>
            </>

        </>
    )
}