import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import "./FinancialData.sass"
import React, {useEffect} from "react";
import {fetchFinTerms} from "../../../store/slices/FinTermsSlice";
import {Loader} from "../../loader/Loader";
import {Trans} from "react-i18next";
import {FormattedValue} from "../../formattedValue/FormattedValue";

export const FinancialData = () => {

    const {isTermsLoading, terms} = useAppSelector(state => state.finTerms)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchFinTerms())
    }, []);

    if (!terms) return (<></>)

    return ( isTermsLoading ? <Loader/> :
        <>
            <div className="financialData">
                <div className="financialData__row">
                    <div className="financialData__row__item title">
                        <h5><Trans i18nKey="utilized_credit"/></h5>
                    </div>
                    <div className="financialData__row__item bold-text">
                        <span>{
                            terms.partner_credit_limit ? (<FormattedValue value={(terms.used_credit_limit / terms.partner_credit_limit) * 100}/>) : 0} %
                        </span>
                        <span><FormattedValue value={terms.used_credit_limit}/> €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="credit_limit_balance"/></span>
                        <span><FormattedValue value={terms.available_credit_limit}/> €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="credit_limit"/></span>
                        <span><FormattedValue value={terms.partner_credit_limit}/> €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="unpaid_invoices_amount"/></span>
                        <span><FormattedValue value={terms.unpaid_credit_invoice}/> €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="overdue_payments_amount"/></span>
                        <span><FormattedValue value={terms.invoice_total_overdue}/> €</span>
                    </div>
                </div>
                {terms.upcoming_invoice_amount && (
                    <div className="financialData__row">
                        <div className="financialData__row__item small-text">
                            <span><Trans i18nKey="due_date_amount"/></span>
                        </div>
                        <div className="financialData__row__item small-text">
                            <span>{terms.upcoming_invoice_date}</span>
                            <span><FormattedValue value={terms.upcoming_invoice_amount}/> €</span>
                        </div>
                    </div>
                )}

                <div className="financialData__row">
                    <div className="financialData__row__item totle">
                        <h5><Trans i18nKey="used_reserve"/></h5>
                    </div>
                    <div className="financialData__row__item bold-text">
                        <span>{terms.reservation_limit_cash ? <FormattedValue value={(terms.used_reservation_limit/terms.reservation_limit_cash)*100}/> : 0} %</span>
                        <span><FormattedValue value={terms.reservation_limit_cash}/> €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="reserves_balance"/></span>
                        <span><FormattedValue value={(terms.reservation_limit_cash - terms.used_reservation_limit)}/> €</span>
                    </div>
                </div>
                <div className="financialData__row">
                    <div className="financialData__row__item small-text">
                        <span><Trans i18nKey="reserve_limit"/></span>
                        <span><FormattedValue value={terms.reservation_limit_cash}/> €</span>
                    </div>
                </div>
            </div>
        </>
    )
}