import "./Pagination.sass"
import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {updatePage} from "../../store/slices/PagePaginagionSlice";

interface BasePagination {
    count: number;
}

export const BasePagination = (props: BasePagination) => {
    const {page} = useAppSelector((state) => state.page)
    const dispatch = useAppDispatch()
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(updatePage(value))
    };

    return (
        <>
            <div className="catalog__pagination">
                <Stack spacing={2}>
                    <Pagination count={props.count} page={page} onChange={handleChange}/>
                </Stack>
            </div>
        </>
    );
}
