import React from "react";
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import {Trans} from "react-i18next";
import {FormattedValue} from "../../../formattedValue/FormattedValue";

export const OrderTotal = () => {
    const {orderData} = useAppSelector(state => state.order)

    return (
        <div id="total">
            <div className="col-xs-7 col-md-5 ms-auto">
                <table className="table table-sm">
                    <tbody>
                    <tr>
                        <td className="text-colored">
                            <strong><Trans i18nKey="total_without_vat"/></strong></td>
                        <td className="text-end">
                            <span>{orderData && <FormattedValue value={orderData.tax_totals.amount_untaxed}/>} {orderData?.currency && orderData.currency.symbol}</span>
                        </td>
                    </tr>
                    {(orderData && !orderData.is_reserve) && (
                        <tr>
                            <td className="text-colored">
                                <strong>Скидка</strong></td>
                            <td className="text-end">
                                <span>
                                    <FormattedValue
                                        value={
                                        orderData.order_line.reduce((n, { discount, product_uom_qty, price_unit }) => {
                                        const discountAmount = (discount / 100) * (product_uom_qty * price_unit);
                                        return (n + discountAmount)}, 0)}
                                    />
                                    {orderData.currency && orderData.currency.symbol}</span>
                            </td>
                        </tr>
                    )}

                    <tr className="border-black">
                        <td className="text-colored">
                            <strong><Trans i18nKey="created_date"/></strong></td>
                        <td className="text-end">
                            <span>
                                {orderData &&
                                    <FormattedValue
                                        value={orderData.tax_totals.amount_total}
                                    />
                                }
                                {orderData?.currency && orderData.currency.symbol}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}