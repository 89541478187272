export type ProductsFilterType = {
    nds: string | undefined,
    Code: string,
    manufactureCode: string | undefined
    brand: string | undefined
}

export enum PRODUCT_CONDITION_KEYS {
    not_opened = "not_opened",
    ext_opened = "ext_opened",
    ext_int_damaged = "ext_int_damaged",
    damaged = "damaged",
    after_repair = "after_repair",
    half_equipped = "half_equipped",
}

export type FilterItemsType = {
    [key: string]: string;
};

export type CurrencyType = {
    id: number,
    name: string,
    symbol: string
}

export type ProductImagesType = {
    id: number,
    name: string,
    image_1920: string,
    video_url: string
}

export type ProductTagType = {
    id: number,
    name: string,
    color: string
}

export type ProductB2BCategory = {
    id: number,
    name: string
}

export type ProductType = {
    id: number,
    default_code: string,
    name: string,
    image_1920: string,
    qty_available: number,
    lst_price: number,
    product_price: number,
    sale_delay: number,
    manufacturer_id: number,
    currency: any,
    product_tag_ids: ProductTagType[],
    public_categ_ids: any[],
    product_variant_image_ids: ProductImagesType[],
    product_template_image_ids: ProductImagesType[],
    description_sale: string,
    product_b2b_category_ids: ProductB2BCategory[],
    is_display_stock_balance_on_portal: boolean,
    is_display_out_of_stock_on_portal: boolean,
    is_for_order: boolean,
    balance_no_more: number,
    stock_quantity_b2b: number,
    guarantee: any,
}

export type SaleOrderLineType = {
    id: number,
    product_id: ProductType
    product_uom_qty: number,
    requested_qty: number,
    discount: number,
    toOrder?: number | undefined,
    price_unit: number,
    tax_id: {
        id: number,
        name: string,
        amount: number
    },
    price_total: number,
    price_subtotal: number
    order_date: string;
    pdp_warehouse_date: string;
}

export type TaxTotalsType = {
    amount_total: number,
    amount_untaxed: number,
    formatted_amount_total: string,
    formatted_amount_untaxed: string,
}

export type SaleOrderType = {
    id: number,
    name: string,
    reservation_name: string,
    reservation_date: string,
    state: string,
    date_order: string,
    delivery_status: string,
    commitment_date: string,
    amount_untaxed: number,
    is_reserve: boolean,
    is_b2b_active?: boolean,
    is_pre_order?: boolean,
    responsible?: string,
    responsible_id?: {
        id: number,
        name: string,
    };
    tax_totals: TaxTotalsType,
    currency: CurrencyType | undefined,
    tag_ids: {
        id: number,
        name: string,
        color: string
    }[],
    order_line: SaleOrderLineType[]
    user_id: {
        id: number,
        name: string,
        is_dropshipping?: boolean,
        country_id?: number,
    } | undefined,
    is_dropshipping?: boolean,
    delivery_address?: {
        id: number,
        name: string,
        display_name: string,
    },
    dropshipping_user?: {
        id?: number,
        name?: string,
        email?: string,
        city?: string,
        phone?: string,
        postal_code?: string,
        address?: string,
        country?: string,
        country_id?: number,
        company_type?: string,
    },
    comment?: string,
}

export type ReturnLineType = {
    id: number,
    quantity: number,
    price_unit: number,
    amount_total: number,
    invoice_sale_date: string,
    waybill_number: string,
    return_reason: string,
    status: string,
    reject_reason: string,
    comment: string,
    product_data: {
        id: number,
        name: string,
        default_code: string,
        manufacture_code: string
    }
    delivery_type?: string;
    currency_id: CurrencyType | undefined,
}

export type ReturnType = {
    id: 0,
    name: string,
    create_date: string,
    approve_date: string,
    responsible_user: string,
    responsible_partner: string,
    return_state: string,
    departure_number: string,
    delivery_type: string,
    contact_person: string,
    pickup_address_id?: {
        id: number,
        name: string,
    };
    lines: ReturnLineType[]
}

export type WarrantyRequestLineType = {
    id: number,
    quantity: number,
    invoice_sale_date: string,
    order_id: {
        id: number,
        name: string,
    }
    waybill_number: string,
    product_data: {
        id: number,
        name: string,
        default_code: string,
        manufacture_code: string
    }
    barcode: string,
    default_code: string,
    serial_number: string,
    price_unit: number,
    amount_total: number,
    defect_id: {
        id: number,
        name: string,
    }
    comment: string,
    currency_id: CurrencyType | undefined,
}

export type WarrantyRequestType = {
    id: 0,
    name: string,
    create_date: string,
    approve_date: string,
    responsible_user: string,
    responsible_partner: string,
    guarantee_state: string,
    lines: WarrantyRequestLineType[]
}

export type ReturnsLineType = {
    id: number,
    name: string,
    create_date: string,
    responsible_user: string,
    return_state: string,
    contact_person: string
}

export type ReturnsType = {
    lines_data: ReturnsLineType[],
    pages_count: number,
    return_limit: number,
    page_number: number,
    max_return_count: number
}

export type WarrantyRequestsLineType = {
    id: number,
    name: string,
    product_id: {
        id: number,
        name: string,
    };
    defect_id: {
        id: number,
        name: string,
    };
    create_date: string,
    responsible_user: string,
    guarantee_state: string,
    contact_person: string
}

export type WarrantyRequestsType = {
    lines_data: WarrantyRequestsLineType[],
    pages_count: number,
    limit: number,
    page_number: number,
    max_return_count: number
}


export type CreditLimitLineType = {
    id: number,
    credit_limit_type: string,
    current_limit: number,
    requested_limit: number,
    to_approve_limit: number
}

export type CreditLimitType = {
    id: number,
    name?: string,
    create_date: string,
    partner_id: {
        id: number,
        name: string,
    } | undefined,
    creator_partner_id: {
        id: number,
        name: string,
    } | undefined,
    final_approval_date: string,
    state: string,
    note: string,
    lines?: CreditLimitLineType[]
}

export type CreditLimitsType = {
    credit_limit_info: CreditLimitType[],
    credit_limit_list: number[],
    pages_count: number,
    record_limit: number,
    page_number: number,
    max_credit_limit_count: number
}

export type SaleOrdersType = {
    sale_limit: number,
    sale_info: SaleOrderType[],
    page_number: number,
    sale_list: number[],
    max_sale_count: number,
    pages_count: number
}

export type SaleOrdersPaginatedLinesType = {

    id: number,
    create_date: string,
    order_date?: string,
    pdp_warehouse_date?: string;
    dpd_warehouse_date?: string,
    order_id: {
        responsible_id: any;
        delivery_status?: string;
        id: number,
        name: string,
        state: string,
        reservation_date: string,
        date_order: string,
        reservation_name: string
        create_date: string
        responsible: string
    },
    product_id: {
        id: number,
        name: string,
        manufacture_code: string | undefined
    },
    tax_id: {
        id: number,
        name: string,
        amount: number
    },
    currency_id: CurrencyType | undefined,
    product_uom_qty: number,
    price_total: number
    price_subtotal: number
    discount: number
    requested_qty: number
    price_unit: number
    toOrder?: number | undefined,
}

export type SaleOrdersPaginatedDataType = {
    limit: number,
    lines: SaleOrdersPaginatedLinesType[],
    page_number: number,
    max_lines_count: number,
    pages_count: number
}

export type CartType = {
    active: boolean,
    id: number,
    name: string,
    user_id: number,
    amount_with_vat: number,
    reservation_limit: number,
    available_reservation_limit: number,
    is_free_delivery: boolean,
    currency: any,
    cart_lines: any[],
    created_at: string,
    updated_at: string
}