import React, {useEffect, useState} from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {ControlButton} from "../../../controlButton/controlButton";
import {useAppDispatch, useAppSelector} from "../../../../hooks/ReduxHooks";
import {setPriceSortType, updatePage} from "../../../../store/slices/PagePaginagionSlice";
import { BiSortUp, BiSortDown, BiMinusCircle } from 'react-icons/bi'
import {Trans} from "react-i18next";

interface SortControlProps {
    onSortChange: (sortType: string) => void;
    className?: string;
    btnClassName?: string;
}

export const SortControl = ({
                                onSortChange,
                                className = "d-flex justify-content-end gap-1",
                                btnClassName = "",
                            }: SortControlProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [sortType, setSortType] = useState<string>("none");
    const dispatch = useAppDispatch()


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        dispatch(setPriceSortType(sortType));
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedSortType = event.target.value;
        setSortType(selectedSortType);
        onSortChange(selectedSortType);
        handleClose();
        dispatch(setPriceSortType(selectedSortType));
        dispatch(updatePage(1))

    };

    const open = Boolean(anchorEl);
    const id = open ? "sort-popover" : undefined;

    return (
        <div className={className}>
            <ControlButton
                className={btnClassName ? btnClassName : "btn btn-sm border favIcon text-colored"}
                icon="bi bi-arrow-down-up"
                controlButtonHandler={handleClick}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Typography sx={{ p: 2 }}>
                    <RadioGroup value={sortType} onChange={handleSortChange}>
                        <FormControlLabel
                            value="asc"
                            control={<Radio />}
                            label={
                                <>
                                    <BiSortUp style={{ marginRight: 8 }} />
                                    <Trans i18nKey="sort_asc"/>
                                </>
                            }
                        />
                        <FormControlLabel
                            value="desc"
                            control={<Radio />}
                            label={
                                <>
                                    <BiSortDown style={{ marginRight: 8 }} />
                                    <Trans i18nKey="sort_desc"/>
                                </>
                            }
                        />
                        <FormControlLabel
                            value="none"
                            control={<Radio />}
                            label={
                                <>
                                    <BiMinusCircle style={{ marginRight: 8 }} />
                                    <Trans i18nKey="sort_no"/>
                                </>
                            }
                        />
                    </RadioGroup>
                </Typography>
            </Popover>
        </div>
    );
};
