import axios from 'axios';
import {resolve} from './resolve.js';
import {API_AUTH_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";

export async function parseQuestionaryInfoRequest() {
    return await resolve(axios.get(`${API_AUTH_URL}/company/`,
        {
            headers: {
                'session_id': `${getSession()}`
            },
        }
    ).then(res => res));
}

export async function updateQuestionaryInfoRequest(data) {
    return await resolve(axios.patch(`${API_AUTH_URL}/company/`,
        data, {
            headers: {
                'session_id': `${getSession()}`
            },
        }
    ).then(res => res));
}
