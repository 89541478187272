import axios from "axios";
import { resolve } from "./resolve.js";
import {API_BACKEND_URL} from "../constants/consts";
import {getExchangeHeaders} from "./helpers";


export async function fetchProductsRequests(pageNum, categoryId, searchValue, activeFilters, operator, manufacturerSearchValue, sortType, onlyAvailability) {
    let requestUrl = `${API_BACKEND_URL}/products/page/${pageNum}/${categoryId}`;

    let params = {}

    const filters = [
        {
            fields: activeFilters.join(","),
            term: searchValue,
        },
        manufacturerSearchValue && {
            fields: "manufacturer_id",
            term: manufacturerSearchValue,
        }
    ].filter(Boolean);

    if (operator) {
        filters.forEach(filter => {
            if (filter) filter.operator = operator;
        });
    }

    if (sortType && sortType !== "none") {
        params["sort"] = sortType;
    }

    if (filters.length > 0) {
        params["filter"] = JSON.stringify(filters);
    }

    if (onlyAvailability) {
        params["is_available"] = onlyAvailability
    }

    return await resolve(axios.get(requestUrl, {
        params: params,
        headers: getExchangeHeaders(),
    }).then(res => res));
}

export async function fetchSearchProductsRequests(pageNum, searchValue, activeFilters, categId, onlyAvailability,sortType) {
    let requestUrl = `${API_BACKEND_URL}/products/search/`
    let params = {
        "page_number": pageNum,
    }
    if (activeFilters && searchValue) {
        params["filter"] = `${JSON.stringify([{fields: activeFilters.join(","), term: searchValue}])}`
    }
    if (categId) {
        params["categ_id"] = categId
    }
    if (onlyAvailability) {
        params["is_available"] = onlyAvailability
    }
    if (sortType && sortType !== "none") {
        params["sort"] = sortType;
    }


    return await resolve(axios.get(requestUrl,
        {
            params: params,
            headers: getExchangeHeaders(),
        }
    ).then(res => res)
    );
}
