import {memo, useContext, useEffect, useState} from 'react';
import "./CartTable.sass";
import {FavButton} from "../catalog/favButton/FavButton";
import {changeValueInProduct} from "../../api/productCart";
import {NavbarContext} from "../../hooks/UseNavbarContext";
import useDebounce from "../../hooks/UseDebounce";
import * as React from "react";
import {MESSAGE_VARIANT} from "../../constants/consts";
import {MessageContext} from "../../hooks/UseMessage";
import {Link} from "@mui/material";
import {FormattedValue} from "../formattedValue/FormattedValue";
import {useTranslation} from "react-i18next";

interface CartTableTr {
    productData?:any;
    checked?:any;
    clearCartLines?: any;
    setTotalCartBillInfo:any;
    indexLine:number;
    onCheckboxChange?:any;

}


export const CartTableTr = memo((props: CartTableTr) => {

    const [value,setValue] = useState(props.productData.quantity);
    const [isDelete, setIsDelete] = useState(false);
    const [isCollapsedSmallText, setIsCollapsedSmallText] = useState(true);
    const [isCollapsedText, setIsCollapsedText] = useState(true);
    const { setNavbarBadgeTrigger,navbarBadgeTrigger } = useContext(NavbarContext);
    const [triggerEffect, setTriggerEffect] = useState(false);
    const debouncedValue = useDebounce(value, 500)
    const {showMessage} = useContext(MessageContext)
    const { t } = useTranslation();


    const checkValue = (value: number) => {
        if (props.productData.is_display_stock_balance_on_portal &&
            (props.productData.stock_quantity_b2b <= props.productData.balance_no_more) &&
            (value > props.productData.stock_quantity_b2b) &&
            value > 0
        ) {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: t("quantityExceeded", { stock: props.productData.stock_quantity_b2b })
            })
            return false
        }
        return true
    }


    useEffect(() => {
        (async () => {
            if (triggerEffect) {
                    let productId = props.productData.product_id;
                    let productParams = {
                        "quantity": Number(value),
                    }
                    let result = await changeValueInProduct(productId, productParams);
                    setNavbarBadgeTrigger(!navbarBadgeTrigger)
                    if (!result.error) {
                        props.setTotalCartBillInfo(result.data.data)
                        let cartLine = result.data.data.cart_lines.filter((line:any) => {return line.product_id === props.productData.product_id})
                        setTriggerEffect(false);
                    }

            }

        })();
    }, [debouncedValue])

    const handleSetValue = async (event: any) => {let fieldValue = event.target.value.replace(/\D/g, '');
        let inputValue = fieldValue >= 1 ? fieldValue : 1
        if (!checkValue(Number(inputValue))) return
        setValue(Number(inputValue))
        setTriggerEffect(true)
    }

    const ToggleCollapseSmallText = () => {
        setIsCollapsedSmallText(!isCollapsedSmallText);
    };
    const ToggleCollapseText = () => {
        setIsCollapsedText(!isCollapsedText);
    };

    const handleDecrease = () => {
        const inputValue = value <= 1 ? 1 : value - 1
        if (!checkValue(Number(inputValue))) return
        setValue(value <= 1 ? 1 : value - 1)
        setTriggerEffect(true);
    }

    const handleIncrease = () => {
        const inputValue = value + 1
        if (!checkValue(Number(inputValue))) return
        setValue(value + 1)
        setTriggerEffect(true);
    }

    const handleDelete = () => {
        props.clearCartLines([props.productData.id])
        setIsDelete(true)
    }

    if (isDelete) return <></>

    return (
        <tr key={`cart_line_${props.productData.id}`}>
            <td scope="row">
                <input type="checkbox" checked={props.checked} onChange={() => props.onCheckboxChange(props.productData.id)} id="cartProductCheck"/>
            </td>
            <td className="text-center">
                <p>{props.indexLine+1}</p>
            </td>
            <td className="text-center" style={{width:"7%"}}>
                <img loading="lazy"
                     src={props.productData.image_1920}
                     alt="img"
                />
            </td>
            <td>
                <p>{props.productData.default_code}</p>
            </td>
            <td>
                <p>{props.productData.manufacturer_name}</p>
            </td>
            <td className="text-end">
                <FavButton isColored/>
            </td>
            <td className="eclipse-content">
                <div className="buttonText">
                    <a href={`/products/product-card/${props.productData.product_id}`} className={`product-link ${isCollapsedText ? "hidden-text" : "open-text"}`}>
                        {props.productData.name}
                    </a>
                    <button type="button" className="btn" onClick={ToggleCollapseText}>
                        <i className={`bi ${isCollapsedText ? "bi-chevron-down" : "bi-chevron-up"}`}></i>
                    </button>
                </div>
                <div className="buttonText">
                    <p className={`small-text ${isCollapsedSmallText ? "hidden-text" : "open-text"}`}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen book. It has survived not only five
                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                        passages, and more recently with desktop publishing software like Aldus PageMaker including
                        versions of Lorem Ipsum.
                        Why do we use it
                        It is a long established fact that a reader will be distracted by the readable content of a
                        p
                    </p>
                    <button type="button" className="btn" onClick={ToggleCollapseSmallText}><i
                        className={`bi ${isCollapsedSmallText ? "bi-plus-circle" : "bi-dash-circle"}`}></i>
                    </button>
                </div>
            </td>
            <td>
                <div className="quantityBlock">
                    <div className="quantityInputBlock">
                        <button className="btn quantity_btn-control border text-colored number-minus" type="button"
                                onClick={handleDecrease}
                        >-
                        </button>
                        <input className="product__input in_card"
                               min="0"
                               value={value}
                               onChange={handleSetValue}
                        />
                        <button className="btn quantity_btn-control border text-colored number-plus" type="button"
                                onClick={handleIncrease}
                        >+
                        </button>
                    </div>
                    <p className="small-text text-wrap">
                        {props.productData.is_for_order ? t("on_order") : t("stock")}:
                        <span>
                            {props.productData.is_display_stock_balance_on_portal && (
                                props.productData.stock_quantity_b2b <= props.productData.balance_no_more ? (
                                    <span> {props.productData.stock_quantity_b2b}</span>
                                ) : (
                                    <>
                                        <span> {t("moreThan")} {props.productData.balance_no_more}</span>
                                    </>
                                )
                            )}
                        </span>
                        {(props.productData.is_for_order && props?.productData?.days_qty > 0) && (
                            <span>, {t("days").toLowerCase()} {props.productData.days_qty}</span>
                        )}
                    </p>

                </div>
            </td>
            <td>
                <p><FormattedValue value={props.productData.price}/></p>
                <p className="small-text">
                    вкл. 2.85 AKKA/LAA
                </p>
            </td>
            <td>
                <p><FormattedValue value={props.productData.total_price}/></p>
                <p className="small-text">
                    Ставка НДС: 0%
                </p>
            </td>
            <td>
                <i className="delete-cross bi bi-x" onClick={handleDelete}></i>
            </td>
        </tr>
    )
})
