import './QuestionaryPage.sass'
import React, {useEffect, useState, useRef} from "react";
import {ExpandableBlock} from './ExpandableBlock';

import {parseQuestionaryInfoRequest, updateQuestionaryInfoRequest} from "../../api/questionary";
import {
    changeUserRoleForQuestionaryBlock,
    getSessionUserRole,
    checkUserRole,
    setSessionUserRole, createNewRole
} from "../../api/sessionManagement"
import useMessage from "../../hooks/UseMessage";
import {COUNTRIES, MESSAGE_VARIANT} from "../../constants/consts";
import Message from "../../components/message/Message";
import { Trans, useTranslation } from "react-i18next";


export const QuestionaryPage = () => {


    const [isBlocked, setIsBlocked] = useState(false);
    const [, updateState] = React.useState();
    const questionaryData = useRef<any>()
    const [acceptAgreement, setAcceptAgreement] = useState(false);
    const [agreementError, setAgreementError] = useState(false);

    const {message, hideMessage, showMessage} = useMessage()
    const { t } = useTranslation();


    const [openItems, setOpenItems] = useState<any>({
        basicInfo: true,
        beneficialOwner: false,
        legalAddress: false,
        officeAddress: false,
        companyBoard:false,
        bankDetails: false,
        shippingAddress: false,
        purchasingDepartment: false,
        accountingDepartment: false,
        companyOrientation: false,
    });


    const toggleItem = (index: any) => {
        setOpenItems({...openItems, [index]: !openItems[index]});
    };

    const parseValueForExtendedBlocks = (dataKey: any) => {
        let extendedBlocksScheme: {}[] = []
        let extendedBlocks = document.querySelectorAll(`div.${dataKey}`)
        extendedBlocks.forEach((element: any, key: any) => {
            let extendedBlocksObject:any = {}
            if (dataKey === "extended_bank") {
                let scheme = {
                    "record_id": element.dataset.recordId === 'null' ? null : parseInt(element.dataset.recordId),
                    "name": element.querySelector('input[data-key=bank-name]').value,
                    "account_number": element.querySelector('input[data-key=bank-account-number]').value,
                    "swift": element.querySelector('input[data-key=bank-swift]').value
                }
                extendedBlocksObject =  isObjectEmpty(scheme) ? null : scheme
            } else if (dataKey === "extended_beneficial") {
                let scheme = {
                    "record_id": element.dataset.recordId === 'null' ? null : parseInt(element.dataset.recordId),
                    "name": element.querySelector('input[data-key=beneficial_owner-name]').value,
                    "nationality": element.querySelector('input[data-key=beneficial_owner-nationality]').value,
                    "identity": element.querySelector('input[data-key=beneficial_owner-identity]').value
                }
                extendedBlocksObject = isObjectEmpty(scheme) ? null : scheme
            }
            else if (dataKey === "company_board") {
                let scheme = {
                    "record_id": element.dataset.recordId === 'null' ? null : parseInt(element.dataset.recordId),
                    "name": element.querySelector('input[data-key=company-board-name]').value,
                }
                extendedBlocksObject = isObjectEmpty(scheme) ? null : scheme
            }
            else {
                let scheme = {
                    "record_id": element.dataset.recordId === 'null' ? null : parseInt(element.dataset.recordId),
                    "name": element.querySelector('input[data-key=shipping-name]').value,
                    "street": element.querySelector('input[data-key=shipping-address]').value,
                    "city": element.querySelector('input[data-key=shipping-city]').value,
                    "postal_code": element.querySelector('input[data-key=shipping-postal-code]').value,
                    "phone": element.querySelector('input[data-key=shipping-phone]').value,
                    "email": element.querySelector('input[data-key=shipping-email]').value,
                    "region": element.querySelector('select[data-key=shipping-region]').value,
                }
                extendedBlocksObject = isObjectEmpty(scheme) ? null : scheme
            }
            extendedBlocksScheme.push(
                extendedBlocksObject
            )
        })
        return extendedBlocksScheme.every(isObjectEmpty) ? [] : extendedBlocksScheme;
    }

    const parseCheckboxesValue = (htmlClass:any) =>{
        let checkedLabels = document.querySelectorAll(`.${htmlClass} input:checked+label`)
        let listOfTextLabels: (string | null)[] = []
        checkedLabels.forEach((label,key)=>{
            listOfTextLabels.push(label.textContent);
        })
        return listOfTextLabels
    }

    const handleClickSubmit = async (event: any) => {
        event.preventDefault();
        if (getSessionUserRole() === "confirmed" && !acceptAgreement) {
            setAgreementError(true);
            showMessage({
                variant: MESSAGE_VARIANT.ERROR,
                text: t("acceptAgreementError")
            });
            return;
        }
        let legalAddress = {
            "record_id": null,
            "street": parseValue("legal-address-street"),
            "postal_code": parseValue("legal-address-postal-code"),
            "city": parseValue("legal-address-city"),
            "region": parseValueSelect("legal-address-region"),
            "phone": parseValue("legal-address-phone"),
            "email": parseValue("legal-address-email"),
        }
        let officeAddress = {
            "record_id": null,
            "street": parseValue("office-address-postal-street"),
            "postal_code": parseValue("office-address-postal-code"),
            "city": parseValue("office-address-city"),
            "region": parseValueSelect("office-address-region"),
            "phone": parseValue("office-address-phone"),
            "email": parseValue("office-address-email"),
        }
        let purchase = {
            "record_id": null,
            "responsible_user": parseValue("purchase-responsible-user"),
            "position": parseValue("purchase-position"),
            "phone": parseValue("purchase-phone-number"),
            "fax": parseValue("purchase-fax-number"),
            "email": parseValue("purchase-email-address"),
        }
        let accountingDepartment = {
            "record_id": null,
            "responsible_user": parseValue("accounting-department-responsible"),
            "phone": parseValue("accounting-department-phone-number"),
            "email": parseValue("accounting-department-email-address"),
        }
        let scheme = {
            "name": parseValue("company-legal-name"),
            "registration_number": parseValue("company-registration-number"),
            "vat": parseValue("company-vat-number"),
            "registration_year": parseValue("company-registration-year"),
            "turnover_last_year": parseValue("company-turnover"),
            "phone": parseValue("company-phone-number"),
            "email": parseValue("company-mail-address"),
            "website": parseValue("company-website"),
            "quantity_empl": parseValue("company-quantity"),
            "legal_address": isObjectEmpty(legalAddress) ? null : legalAddress,
            "office_address": isObjectEmpty(officeAddress) ? null : officeAddress,
            "beneficial_owners": parseValueForExtendedBlocks("extended_beneficial"),
            "boards": parseValueForExtendedBlocks("company_board"),
            "banks": parseValueForExtendedBlocks("extended_bank"),
            "shippings": parseValueForExtendedBlocks("extended_shipping"),
            "purchase": isObjectEmpty(purchase) ? null : purchase,
            "buisnes_type": parseCheckboxesValue('company_business'),
            "assortements": parseCheckboxesValue('assortment_range'),
            "accounting_department": isObjectEmpty(accountingDepartment) ? null : accountingDepartment
        }
        let result = await updateQuestionaryInfoRequest(scheme)
        if (!result.error) {
            if (getSessionUserRole() === "confirmed") {
                await createNewRole()
                await changeUserRoleForQuestionaryBlock()
                await handleCheckUserRoleForBlock()
                showMessage({
                    variant: MESSAGE_VARIANT.SUCCESS,
                    text: t("dataSubmittedBlocked")
                })
            }
            else {
                showMessage({
                    variant: MESSAGE_VARIANT.SUCCESS,
                    text: t("dataChanged")
                })
            }
            await parseQuestionaryInfo();
        }
        else {
            showMessage({
                variant: MESSAGE_VARIANT.ERROR,
                text: t("updateError")
            })
        }

    }

    const isObjectEmpty = (obj:any) => {
        for (let key in obj) {
            if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
                return false;
            }
        }
        return true;
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault()
        await handleClickSubmit(event)
    }
    const handleCheckUserRoleForBlock = async () => {
        let result = await checkUserRole()
        if (!result.error) {
            let userRole = result.data.data.role
            setSessionUserRole(userRole);
        }
        if (getSessionUserRole() === "wait-approve") {
            setIsBlocked(true);
        } else {
            setIsBlocked(false);
        }
    }


    useEffect(() => {
        (async () => {
            await parseQuestionaryInfo();
        })();
    }, []);

    const parseValue = (dataKey: any) => {
        let element: any = document.querySelector(`input[data-key='${dataKey}']`)
        return element?.value
    }

    const parseValueSelect = (dataKey: any) => {
        let element: any = document.querySelector(`select[data-key='${dataKey}']`)
        return element?.value
    }

    async function parseQuestionaryInfo() {
        let result = await parseQuestionaryInfoRequest();
        if (!result.error) {
            questionaryData.current = result.data.data
            forceUpdate();
        }
        else {
            showMessage({
                variant: MESSAGE_VARIANT.ERROR,
                text: t("updateError")
            })
        }
    }

    // @ts-ignore
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const handleInputChange = (event: any) => {
        questionaryData.current = event.target.value;
        forceUpdate();
    };
    const emptyBankDetail = {name: '', account_number: '', swift: '', record_id: 'null'};
    const emptyBeneficialOwner = {name: '', nationality: '', identity: '', record_id: 'null'};
    const emptyShippingAddress = {
        name: '',
        street: '',
        city: '',
        postal_code: '',
        region: '',
        phone: '',
        email: '',
        record_id: 'null'
    };
    const emptyCompanyBoard = {name:'',record_id:'null'}

    const renderBankDetailContent = (block: any, index: any, handleInputChange: any) => (
        <div className="extended_general_block_content extended_bank" data-record-id={block.record_id}>
            <div className="questionary__field">
                <label>{t("bankName")}</label>
                <input
                    disabled={isBlocked}
                    data-key="bank-name"
                    type="text"
                    name="name"
                    onChange={(e) => handleInputChange(block.id, "name", e.target.value)}
                    value={block.name}
                />
            </div>
            <div className="questionary__field">
                <label>{t("accountNumber")}</label>
                <input
                    disabled={isBlocked}
                    data-key="bank-account-number"
                    type="text"
                    name="account_number"
                    onChange={(e) => handleInputChange(block.id, "account_number", e.target.value)}
                    value={block.account_number}
                />
            </div>
            <div className="questionary__field">
                <label>{t("swift")}</label>
                <input
                    disabled={isBlocked}
                    data-key="bank-swift"
                    type="text"
                    name="swift"
                    onChange={(e) => handleInputChange(block.id, "swift", e.target.value)}
                    value={block.swift}
                />
            </div>
        </div>
    );

    const renderCompanyBoardContent = (block: any, index: any, handleInputChange: any) => (
        <div className="extended_general_block_content company_board" data-record-id={block.record_id}>
            <div className="questionary__field">
                <label>{t("name")}</label>
                <input
                    disabled={isBlocked}
                    data-key="company-board-name"
                    type="text"
                    name="name"
                    onChange={(e) => handleInputChange(block.id, "name", e.target.value)}
                    value={block.name}
                />
            </div>
        </div>
    );

    const renderBeneficialOwnerContent = (block: any, index: any, handleInputChange: any) => (
        <div className="extended_general_block_content extended_beneficial" data-record-id={block.record_id}>
            <div className="questionary__field">
                <label>{t("name")}</label>
                <input
                    disabled={isBlocked}
                    data-key="beneficial_owner-name"
                    type="text"
                    name="name"
                    onChange={(e) => handleInputChange(block.id, "name", e.target.value)}
                    value={block.name}
                />
            </div>
            <div className="questionary__field">
                <label>{t("nationality")}</label>
                <input
                    disabled={isBlocked}
                    type="text"
                    name="nationality"
                    data-key="beneficial_owner-nationality"
                    onChange={(e) => handleInputChange(block.id, "nationality", e.target.value)}
                    value={block.nationality}
                />
            </div>
            <div className="questionary__field">
                <label>{t("identityOrDob")}</label>
                <input
                    disabled={isBlocked}
                    data-key="beneficial_owner-identity"
                    type="text"
                    name="identity"
                    onChange={(e) => handleInputChange(block.id, "identity", e.target.value)}
                    value={block.identity}
                />
            </div>
        </div>
    )

    const renderShippingAddressContent = (block: any, index: any, handleInputChange: any) => (
        <div className="extended_general_block_content extended_shipping" data-record-id={block.record_id}>
            <div className="questionary__field">
                <label>{t("warehouseName")}</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-name"
                    type="text"
                    name="shipping-name"
                    onChange={(e) => handleInputChange(block.id, "name", e.target.value)}
                    value={block.name}
                />
            </div>
            <div className="questionary__field">
                <label>{t("shippingAddress")}</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-address"
                    type="text"
                    name="shipping-address"
                    onChange={(e) => handleInputChange(block.id, "street", e.target.value)}
                    value={block.street}
                />
            </div>
            <div className="questionary__field">
                <label>{t("city")}</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-city"
                    type="text"
                    name="shipping-city"
                    onChange={(e) => handleInputChange(block.id, "city", e.target.value)}
                    value={block.city}
                />
            </div>
            <div className="questionary__field">
                <label>{t("postalCode")}</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-postal-code"
                    type="text"
                    name="shipping-postal-code"
                    onChange={(e) => handleInputChange(block.id, "postal_code", e.target.value)}
                    value={block.postal_code}
                />
            </div>
            <div className="questionary__field">
                <label>{t("country")}</label>
                <select
                    disabled={isBlocked}
                    data-key="shipping-region"
                    name="shipping-region"
                    onChange={(e) => handleInputChange(block.id, "region", e.target.value)}
                >
                    <option defaultValue={block.region}>{block.region}</option>
                    {Object.entries(COUNTRIES).map(([code, name]) => (
                        <option key={code} value={code}>
                            {t(`countries.${code}`, name)}
                        </option>
                    ))}
                </select>
            </div>
            <div className="questionary__field">
                <label>{t("phoneOfWarehouse")}</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-phone"
                    type="text"
                    name="shipping-phone"
                    onChange={(e) => handleInputChange(block.id, "phone", e.target.value)}
                    value={block.phone}
                />
            </div>
            <div className="questionary__field">
                <label>{t("emailOfWarehouse")}</label>
                <input
                    disabled={isBlocked}
                    data-key="shipping-email"
                    type="text"
                    name="shipping-email"
                    onChange={(e) => handleInputChange(block.id, "email", e.target.value)}
                    value={block.email}
                />
            </div>
        </div>
    );


    const inputPropsStyles = {
        width: "100%",
        fontSize: "12px",
        '& .MuiFormLabel-root': {
            fontSize: '0.8rem',
            width: '100%',
        },
        '& .MuiOutlinedInput-input': {
            fontSize: '0.8rem',
            width: '100%',
        },
    };


    return <>
        <form className="questionary" onSubmit={handleSubmit}>
            <div className="questionary__wrapper">
                <div className={`questionary__item ${openItems.basicInfo ? "open" : "closed"}`}>
                    <div className="questionary__name" onClick={() => toggleItem("basicInfo")}>
                        {t("basicInfo")}
                    </div>
                    <div className={`questionary__content ${openItems.basicInfo ? "" : "d-none"}`}>
                        <div className="questionary__field">
                            <label>{t("companyLegalName")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="company-legal-name"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.name}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("registrationNumber")}</label>
                            <input
                                disabled={isBlocked}
                                type="text"
                                name="name"
                                data-key="company-registration-number"
                                defaultValue={questionaryData.current?.registration_number}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("vatNumber")}</label>
                            <input
                                disabled={isBlocked}
                                type="text"
                                name="name"
                                data-key="company-vat-number"
                                defaultValue={questionaryData.current?.vat}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("companyRegistrationYear")}</label>
                            <input
                                disabled={isBlocked}
                                type="text"
                                name="name"
                                data-key="company-registration-year"
                                defaultValue={questionaryData.current?.registration_year}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("turnoverLastYear")}</label>
                            <input
                                disabled={isBlocked}
                                type="text"
                                name="name"
                                data-key="company-turnover"
                                defaultValue={questionaryData.current?.turnover_last_year}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("phoneNumber")}</label>
                            <input
                                disabled={isBlocked}
                                type="text"
                                name="name"
                                data-key="company-phone-number"
                                defaultValue={questionaryData.current?.phone}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("emailAddress")}</label>
                            <input
                                disabled={isBlocked}
                                type="text"
                                name="name"
                                data-key="company-mail-address"
                                defaultValue={questionaryData.current?.email}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("website")}</label>
                            <input
                                disabled={isBlocked}
                                type="text"
                                name="name"
                                data-key="company-website"
                                defaultValue={questionaryData.current?.website}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("quantityOfEmployees")}</label>
                            <input
                                disabled={isBlocked}
                                type="text"
                                name="name"
                                data-key="company-quantity"
                                defaultValue={questionaryData.current?.quantity_empl}
                            />
                        </div>
                    </div>
                </div>
                <div className={`questionary__item ${openItems.companyBoard ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('companyBoard')}>
                        {t("companyBoard")}
                    </div>
                    <div className={`questionary__content ${openItems.companyBoard ? "" : "d-none"}`}>
                        {questionaryData.current?.boards.length === 0 &&
                            <ExpandableBlock renderContent={renderCompanyBoardContent}
                                             initialData={[emptyCompanyBoard]}
                                             emptyBlock={emptyCompanyBoard}/>}
                        {questionaryData.current?.boards.length >= 1 &&
                            <ExpandableBlock renderContent={renderCompanyBoardContent}
                                             initialData={questionaryData.current?.boards}
                                             emptyBlock={emptyCompanyBoard}/>}
                    </div>
                </div>
                <div className={`questionary__item ${openItems.beneficialOwner ? 'open' : 'closed'}`}>
                    <div className="questionary__name" onClick={() => toggleItem('beneficialOwner')}>
                        {t("beneficialOwnerDetails")}
                    </div>
                    <div className={`questionary__content ${openItems.beneficialOwner ? "" : "d-none"}`}>
                        {questionaryData.current?.beneficial_owners.length === 0 &&
                            <ExpandableBlock renderContent={renderBeneficialOwnerContent}
                                             initialData={[emptyBeneficialOwner]} emptyBlock={emptyBeneficialOwner}/>}
                        {questionaryData.current?.beneficial_owners.length >= 1 &&
                            <ExpandableBlock renderContent={renderBeneficialOwnerContent}
                                             initialData={questionaryData.current?.beneficial_owners}
                                             emptyBlock={emptyBeneficialOwner}/>}
                    </div>
                </div>
                <div className={`questionary__item ${openItems.legalAddress ? "open" : "closed"}`}>
                    <div className="questionary__name" onClick={() => toggleItem("legalAddress")}>
                        {t("legalAddress")}
                    </div>
                    <div className={`questionary__content ${openItems.legalAddress ? "" : "d-none"}`}>
                        <div className="questionary__field">
                            <label>{t("legalAddress")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="legal-address-street"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.legal_address?.street}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("city")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="legal-address-city"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.legal_address?.city}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("postalCode")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="legal-address-postal-code"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.legal_address?.postal_code}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("country")}</label>
                            <select
                                disabled={isBlocked}
                                data-key="legal-address-region"
                                name="name"
                                defaultValue={questionaryData.current?.legal_address?.region}
                            >
                                <option value={questionaryData.current?.legal_address?.region}>
                                    {questionaryData.current?.legal_address?.region}
                                </option>
                                {Object.entries(COUNTRIES).map(([code, name]) => (
                                    <option key={code} value={code}>
                                        {t(`countries.${code}`, name)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="questionary__field">
                            <label>{t("phoneNumber")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="legal-address-phone"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.legal_address?.phone}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("emailAddress")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="legal-address-email"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.legal_address?.email}
                            />
                        </div>
                    </div>
                </div>
                <div className={`questionary__item ${openItems.officeAddress ? "open" : "closed"}`}>
                    <div className="questionary__name" onClick={() => toggleItem("officeAddress")}>
                        {t("officeAddress")}
                    </div>
                    <div className={`questionary__content ${openItems.officeAddress ? "" : "d-none"}`}>
                        <div className="questionary__field">
                            <label>{t("officeAddress")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="office-address-postal-street"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.office_address?.street}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("city")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="office-address-city"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.office_address?.city}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("postalCode")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="office-address-postal-code"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.office_address?.postal_code}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("country")}</label>
                            <select
                                disabled={isBlocked}
                                data-key="office-address-region"
                                name="name"
                                defaultValue={questionaryData.current?.office_address?.region}
                            >
                                <option value={questionaryData.current?.office_address?.region}>
                                    {questionaryData.current?.office_address?.region}
                                </option>
                                {Object.entries(COUNTRIES).map(([code, name]) => (
                                    <option key={code} value={code}>
                                        {t(`countries.${code}`, name)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="questionary__field">
                            <label>{t("phoneNumber")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="office-address-phone"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.office_address?.phone}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("emailAddress")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="office-address-email"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.office_address?.email}
                            />
                        </div>
                    </div>
                </div>
                <div className={`questionary__item ${openItems.bankDetails ? "open" : "closed"}`}>
                    <div className="questionary__name" onClick={() => toggleItem("bankDetails")}>
                        {t("bankDetails")}
                    </div>
                    <div className={`questionary__content ${openItems.bankDetails ? "" : "d-none"}`}>
                        {questionaryData.current?.banks.length === 0 && (
                            <ExpandableBlock
                                renderContent={renderBankDetailContent}
                                initialData={[emptyBankDetail]}
                                emptyBlock={emptyBankDetail}
                            />
                        )}
                        {questionaryData.current?.banks.length >= 1 && (
                            <ExpandableBlock
                                renderContent={renderBankDetailContent}
                                initialData={questionaryData.current?.banks}
                                emptyBlock={emptyBankDetail}
                            />
                        )}
                    </div>
                </div>
                <div className={`questionary__item ${openItems.shippingAddress ? "open" : "closed"}`}>
                    <div className="questionary__name" onClick={() => toggleItem("shippingAddress")}>
                        {t("shippingAddress")}
                    </div>
                    <div className={`questionary__content ${openItems.shippingAddress ? "" : "d-none"}`}>
                        {questionaryData.current?.shippings.length === 0 && (
                            <ExpandableBlock
                                renderContent={renderShippingAddressContent}
                                initialData={[emptyShippingAddress]}
                                emptyBlock={emptyShippingAddress}
                            />
                        )}
                        {questionaryData.current?.shippings.length >= 1 && (
                            <ExpandableBlock
                                renderContent={renderShippingAddressContent}
                                initialData={questionaryData.current?.shippings}
                                emptyBlock={emptyShippingAddress}
                            />
                        )}
                    </div>
                </div>
                <div className={`questionary__item ${openItems.purchasingDepartment ? "open" : "closed"}`}>
                    <div className="questionary__name" onClick={() => toggleItem("purchasingDepartment")}>
                        {t("purchasingDepartment")}
                    </div>
                    <div className={`questionary__content ${openItems.purchasingDepartment ? "" : "d-none"}`}>
                        <div className="questionary__field">
                            <label>{t("personResponsibleForOrdering")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="purchase-responsible-user"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.purchase?.responsible_user}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("position")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="purchase-position"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.purchase?.position}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("phoneNumber")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="purchase-phone-number"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.purchase?.phone}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("faxNumber")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="purchase-fax-number"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.purchase?.fax}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("emailAddress")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="purchase-email-address"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.purchase?.email}
                            />
                        </div>
                    </div>
                </div>
                <div className={`questionary__item ${openItems.companyOrientation ? "open" : "closed"}`}>
                    <div className="questionary__name" onClick={() => toggleItem("companyOrientation")}>
                        {t("companyBusinessOrientation")}
                    </div>
                    <div className={`questionary__content ${openItems.companyOrientation ? "" : "d-none"}`}>
                        <div className="questionary__checkboxes company_business">
                            <span className="checkbox-span">{t("companyBusinessType")}</span>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.buisnes_type.includes("Wholesale")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="whosale"
                                />
                                <label htmlFor="whosale">{t("wholesale")}</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.buisnes_type.includes("Retail")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="retail"
                                />
                                <label htmlFor="retail">{t("retail")}</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.buisnes_type.includes("Distributor")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="distributor"
                                />
                                <label htmlFor="distributor">{t("distributor")}</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.buisnes_type.includes("Trader")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="trader"
                                />
                                <label htmlFor="trader">{t("trader")}</label>
                            </div>
                        </div>
                        <div className="questionary__checkboxes assortment_range">
                            <span className="checkbox-span">{t("assortmentRange")}</span>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.assortements.includes("CE")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="ce"
                                />
                                <label htmlFor="ce">{t("ce")}</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.assortements.includes("FS")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="fs"
                                />
                                <label htmlFor="fs">{t("fs")}</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.assortements.includes("PHOTO")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="photo"
                                />
                                <label htmlFor="photo">{t("photo")}</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.assortements.includes("GSM")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="gsm"
                                />
                                <label htmlFor="gsm">{t("gsm")}</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.assortements.includes("BI")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="bi"
                                />
                                <label htmlFor="bi">{t("bi")}</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.assortements.includes("SDA")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="sda"
                                />
                                <label htmlFor="sda">{t("sda")}</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.assortements.includes("IT")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="it"
                                />
                                <label htmlFor="it">{t("it")}</label>
                            </div>
                            <div className="questionary__field questionary--checkbox">
                                <input
                                    defaultChecked={questionaryData.current?.assortements.includes("NAVI")}
                                    disabled={isBlocked}
                                    type="checkbox"
                                    id="navi"
                                />
                                <label htmlFor="navi">{t("navi")}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`questionary__item ${openItems.accountingDepartment ? "open" : "closed"}`}>
                    <div className="questionary__name" onClick={() => toggleItem("accountingDepartment")}>
                        {t("accountingDepartment")}
                    </div>
                    <div className={`questionary__content ${openItems.accountingDepartment ? "" : "d-none"}`}>
                        <div className="questionary__field">
                            <label>{t("accountingResponsible")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="accounting-department-responsible"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.accounting_department?.responsible_user}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("phoneNumber")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="accounting-department-phone-number"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.accounting_department?.phone}
                            />
                        </div>
                        <div className="questionary__field">
                            <label>{t("emailAddress")}</label>
                            <input
                                disabled={isBlocked}
                                data-key="accounting-department-email-address"
                                type="text"
                                name="name"
                                defaultValue={questionaryData.current?.accounting_department?.email}
                            />
                        </div>
                    </div>
                </div>
                {getSessionUserRole() === "confirmed" && (
                    <div className="agreement-checkbox">
                        <input
                            type="checkbox"
                            id="agreement-checkbox"
                            checked={acceptAgreement}
                            onChange={() => {
                                setAcceptAgreement(!acceptAgreement);
                                setAgreementError(false);
                            }}
                            style={{ borderColor: agreementError ? "red" : undefined }}
                        />
                        <label htmlFor="agreement-checkbox" style={{ color: agreementError ? "red" : undefined }}>
                            {t("acceptAgreement")}{" "}
                            <a href="/terms-service" target="_blank">
                                {t("agreement")}
                            </a>
                        </label>
                    </div>
                )}
                <button type="submit" className="btn-submit btn-questionary" disabled={isBlocked}>
                    {isBlocked ? t("submitButtonBlocked") : t("submitButton")}
                </button>
            </div>
        </form>
        {message && <Message children={message.text} variant={message.variant} hideMessage={hideMessage}/>}
    </>
}