import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    DISPLAY_TABLE_FIELDS,
    DISPLAY_TYPE,
    EMPTY_STRING,
    LocalStorageDisplayFields,
    LocalStorageSearchBy,
    SEARCH_PARAMS
} from "../../constants/consts";
import {FilterItemsType} from "../../components/catalog/types";

export const getActiveFilters = () => {
    const searchByStr = localStorage.getItem(LocalStorageSearchBy)
    return searchByStr ? JSON.parse(searchByStr) : Object.keys(SEARCH_PARAMS)
}

export const getActiveDisplayFields = () => {
    const tableFieldsStr = localStorage.getItem(LocalStorageDisplayFields)
    return tableFieldsStr ? JSON.parse(tableFieldsStr) : Object.keys(DISPLAY_TABLE_FIELDS)
}


type initialStateType = {
    displayType: DISPLAY_TYPE;
    page: number;
    searchValue: string,
    sortType: string;
    operator: string,
    manufacturerSearchValue: string,
    activeFilters: string[],
    activeDisplayFields: string[]
}

const initialState: initialStateType  = {
    page: 1,
    sortType: "none",
    displayType: DISPLAY_TYPE.TABLE,
    searchValue: EMPTY_STRING,
    operator: EMPTY_STRING,
    manufacturerSearchValue: EMPTY_STRING,
    activeFilters: getActiveFilters(),
    activeDisplayFields: getActiveDisplayFields()
};

const getUpdatedActiveFilters = (action: { payload: any; type: string; }) => {
    let activeFilters = getActiveFilters();
    const filter = action.payload;

    if (action.type === "page/setActiveFilters") {
        if (filter) {
            activeFilters.push(filter);
        }
    } else if (action.type === "page/removeActiveFilters") {
        activeFilters = activeFilters.filter((item: keyof FilterItemsType) => item !== filter);
    }

    const uniqueList = Object.keys(SEARCH_PARAMS)
        .filter(param => activeFilters.some((item: keyof FilterItemsType) => item === param));

    localStorage.setItem(LocalStorageSearchBy, JSON.stringify(uniqueList));
    return uniqueList
};

const getUpdatedActiveDisplayFields = (action: { payload: any; type: string; }) => {
    let activeDisplayFields = getActiveDisplayFields();
    const filter = action.payload;

    if (action.type === "page/setActiveDisplayFields") {
        activeDisplayFields.push(filter);
    } else if (action.type === "page/removeActiveDisplayFields") {
        activeDisplayFields = activeDisplayFields.filter((item: keyof FilterItemsType) => item !== filter);
    }

    const uniqueList = Object.keys(DISPLAY_TABLE_FIELDS)
        .filter(param => activeDisplayFields.some((item: keyof FilterItemsType) => item === param));

    localStorage.setItem(LocalStorageDisplayFields, JSON.stringify(uniqueList));
    return uniqueList
};

const pagePaginationSlice = createSlice({
    name: "page",
    initialState,
    reducers: {

        updatePage: (state, action) => {
            state.page = action.payload
        },

        switchDisplayType: (state) => {
            state.displayType = state.displayType === DISPLAY_TYPE.TABLE ? DISPLAY_TYPE.GRID : DISPLAY_TYPE.TABLE
        },

        setDisplayType: (state, action) => {
            state.displayType = action.payload
        },
        setPriceSortType: (state, action: PayloadAction<string>) => {
            state.sortType = action.payload;
        },
        setCatalogSearchValue: (state, action) => {
            state.searchValue = action.payload
        },
        setCatalogFilterValue: (state, action) => {
            state.operator = "in"
            state.searchValue = action.payload.attribute
            state.activeFilters = ["product_template_attribute_value_ids", "product_template_variant_value_ids"]
            state.manufacturerSearchValue = action.payload.fields
        },
        removeCatalogFilterValue: (state, action) => {
            state.operator = ""
            state.searchValue = action.payload.attribute
            state.activeFilters = ["name", "default_code"]
            state.manufacturerSearchValue = action.payload.fields
        },

        setActiveFilters: (state, action) => {
            const uniqueList = getUpdatedActiveFilters(action)
            state.activeFilters = uniqueList
        },
        setNonStoredFilters: (state, action) => {
            state.activeFilters = action.payload
        },

        removeActiveFilters: (state, action) => {
            const uniqueList = getUpdatedActiveFilters(action)
            state.activeFilters = uniqueList
        },
        setActiveDisplayFields: (state, action) => {
            const uniqueList = getUpdatedActiveDisplayFields(action)
            state.activeDisplayFields = uniqueList
        },

        removeActiveDisplayFields: (state, action) => {
            const uniqueList = getUpdatedActiveDisplayFields(action)
            state.activeDisplayFields = uniqueList
        },


    }
});

export const {
    updatePage,
    switchDisplayType,
    setDisplayType,
    setCatalogSearchValue,
    removeCatalogFilterValue,
    setCatalogFilterValue,
    setActiveFilters,
    setNonStoredFilters,
    removeActiveFilters,
    setActiveDisplayFields,
    removeActiveDisplayFields,
    setPriceSortType
} = pagePaginationSlice.actions;
export default pagePaginationSlice.reducer;
