interface FormattedValueProps {
    value: number | undefined | null;
    accuracy?: number;
}

export const FormattedValue = (props: FormattedValueProps) => {
    const { value, accuracy } = props;

    if (value === undefined || value === null) {
        return <></>;
    }

    return <>{value.toFixed(accuracy || 2)}</>;
};