// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ordersTableWrapper {
  margin: 0 auto;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ordersTableWrapper .ordersTable {
  width: 100%;
}
.ordersTableWrapper .ordersTable__header tr {
  font-size: 12px;
}
.ordersTableWrapper .ordersTable__body tr {
  font-size: 12px;
  vertical-align: top;
}
.ordersTableWrapper .ordersTable__body .buttonText {
  display: flex;
  align-items: flex-start;
}
.ordersTableWrapper .ordersTable__body .buttonText p {
  margin: initial;
}
.ordersTableWrapper .ordersTable__body .buttonText button {
  padding: initial;
  color: rgb(110, 209, 243);
}
.ordersTableWrapper .ordersTable__body .small-text {
  font-size: 10px;
  color: gray;
  font-weight: 400;
}
.ordersTableWrapper .ordersTable__body .eclipse-content {
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ordersTableWrapper .ordersTable__body div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
}
.ordersTableWrapper .ordersTable__body td .quantityBlock {
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 150px;
  padding: 5px;
}
.ordersTableWrapper .ordersTable__body td .quantityBlock span {
  color: black;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/orders/ordersContent/OrdersContent.sass"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AACF;AAEE;EACE,WAAA;AAAJ;AAGM;EACE,eAAA;AADR;AAIM;EACE,eAAA;EACA,mBAAA;AAFR;AAIM;EACE,aAAA;EACA,uBAAA;AAFR;AAIQ;EACE,eAAA;AAFV;AAIQ;EACE,gBAAA;EACA,yBAAA;AAFV;AAIM;EACE,eAAA;EACA,WAAA;EACA,gBAAA;AAFR;AAIM;EACE,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAFR;AAIM;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;AAFR;AAMQ;EACE,2BAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;AAJV;AAMU;EACE,YAAA;EACA,iBAAA;AAJZ","sourcesContent":[".ordersTableWrapper\n  margin: 0 auto\n  overflow: auto\n  height: 100%\n  display: flex\n  flex-direction: column\n  justify-content: space-between\n\n\n  .ordersTable\n    width: 100%\n\n    &__header\n      tr\n        font-size: 12px\n\n    &__body\n      tr\n        font-size: 12px\n        vertical-align: top\n\n      .buttonText\n        display: flex\n        align-items: flex-start\n\n        p\n          margin: initial\n\n        button\n          padding: initial\n          color: rgb(110, 209, 243)\n\n      .small-text\n        font-size: 10px\n        color: gray\n        font-weight: 400\n\n      .eclipse-content\n        width: 350px\n        white-space: nowrap\n        overflow: hidden\n        text-overflow: ellipsis\n\n      div\n        white-space: nowrap\n        overflow: hidden\n        text-overflow: ellipsis\n        max-width: 350px\n\n      td\n\n        .quantityBlock\n          border: 1px solid lightgray\n          border-radius: 10px\n          width: 150px\n          padding: 5px\n\n          span\n            color: black\n            font-weight: bold\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
