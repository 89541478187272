import { Form } from "./Form";
import { useSignIn } from "../../hooks/UseSignIn.hook";
import {Trans, useTranslation} from "react-i18next";

export const SignIn = () => {
    const { handleSignIn } = useSignIn();
    const { t } = useTranslation();

    return (
        <Form title={t("signInTitle")} handleClick={handleSignIn} />
    );
};