import { SignUp } from '../../components/auth/SignUp';
import { AuthContainer, AuthContainerChild } from "../../components/auth/styles";
import { Trans } from "react-i18next";

export const SignUpPage = () => {
    return (
        <AuthContainer>
            <AuthContainerChild>
                <h1>
                    <Trans i18nKey="signUp"/>
                </h1>
                <SignUp/>
            </AuthContainerChild>
        </AuthContainer>
    );
};
