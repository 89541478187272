import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem';
import {ReturnTable} from "../returnTable/ReturnTable";
import {inputAddressStyleSM, inputProps, inputStyle, inputStyleSM} from "../ProductReturns";
import React from "react";
import {useAppSelector} from "../../../hooks/ReduxHooks";
import {ROUTE_URLS} from "../../../constants/consts";
import {createBackendReturn, CreateReturnDataType} from '../../../api/returns';
import {useNavigate} from "react-router-dom";
import {Trans} from "react-i18next";
import {t} from "i18next";

export const SubmitForm = () => {

    const {linesToReturn} = useAppSelector(state => state.newReturn)

    const navigate = useNavigate();


    const handleCreateReturn = async () => {
        if (!linesToReturn.length) return
        const newReturnData: CreateReturnDataType = {
            "lines": linesToReturn.map((el) => {
                return ({
                        "id": el.lineId,
                        "quantity": el.quantity,
                        "state": el.condition,
                        "comment": el.description,
                    }
                )
            })
        }
        let result = await createBackendReturn(newReturnData)
        if (result.error) return
        navigate(`${ROUTE_URLS.RETURNS}/${result?.data?.data?.return_id}`)
    }

    const handleSubmit = () => {
        return handleCreateReturn()
    }

    const deliveryAddress = [
        {
            id: 1,
            name: "Address 1"
        },
        {
            id: 2,
            name: "Address 2"
        }
    ]

    return (
        <>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': {m: 1, width: '25ch'},
                }}
                noValidate
                autoComplete="off"
            >
                <h5><Trans i18nKey="make_application"/></h5>
                <TextField
                    id="outlined-basic"
                    size="small"
                    label={t('contact_person')}
                    variant="outlined"
                    inputProps={inputProps}
                    required
                    InputProps={{
                        sx: {
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(110, 209, 243)',
                                outline: 'none',
                            },
                        },
                    }}
                    sx={inputStyleSM}
                />

                <TextField
                    id="outlined-basic"
                    size="small"
                    label={t('phone')}
                    variant="outlined"
                    inputProps={inputProps}
                    required
                    InputProps={{
                        sx: {
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(110, 209, 243)',
                                outline: 'none',
                            },
                        },
                    }}
                    sx={inputStyleSM}
                />

                <TextField
                    id="outlined-select-currency"
                    select
                    size="small"
                    label={t('delivery_address')}
                    inputProps={inputProps}
                    defaultValue={-1}
                    required
                    InputProps={{
                        sx: {
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(110, 209, 243)',
                                outline: 'none',
                            },
                        },
                    }}
                    sx={inputAddressStyleSM}
                    className="delivery_address w-100"
                >
                    <MenuItem
                        key={`address_option_0`}
                        value={-1}
                    > </MenuItem>
                    {deliveryAddress.map((address) => (
                        <MenuItem
                            key={`address_option_${address.id}`}
                            value={address.name}
                        >
                            {address.name}
                        </MenuItem>
                    ))}
                </TextField>

            </Box>
            <ReturnTable/>
            <button type="button" className={`btn btn-search`} disabled={!linesToReturn.length}
                    onClick={handleSubmit}><Trans i18nKey="create_button"/>
            </button>
        </>
    )
}