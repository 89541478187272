import {createContext} from "react";



interface inactiveOrdersContextType {
    inactiveOrders: any[]
}


const inactiveOrderLinesContextValue: inactiveOrdersContextType = {
    inactiveOrders: [],
};

export const InactiveOrdersContext = createContext<inactiveOrdersContextType>(inactiveOrderLinesContextValue)
