import { useAppDispatch } from "./ReduxHooks";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import {clearLocalStorage, setSession, setSessionUserRole} from "../api/sessionManagement";
import {ROUTE_URLS} from "../constants/consts";
import { userLogin } from "../api/userRegistration";
import Swal from 'sweetalert2';
import {Trans, useTranslation} from "react-i18next";

interface UseSignUpReturnValues {
    handleSignUp: (
        email: string, password: string, region: string, companyName: string, vat: string, phone: string, registration: string, zip: string, city: string, address: string, owners: string[], boards: string[]
    ) => void;
}

export const useSignUp = (): UseSignUpReturnValues => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const optionsForAlertError: any = {
        title: t("oops"),
        text: t("registrationError"),
        icon: "error",
        confirmButtonText: t("confirmButton"),
        confirmButtonColor: "#0ebfff"
    };

    const optionsForAlertWithUserConflict: any = {
        title: t("oops"),
        text: t("userConflict"),
        icon: "error",
        confirmButtonText: t("confirmButton"),
        confirmButtonColor: "#0ebfff"
    };


    const handleSignUp = useCallback(async (
        email: string, password: string, region: string, companyName: string, vat: string, phone: string, registration: string, zip: string, city: string, address: string, owners: string[], boards: string[]
    ) => {
        clearLocalStorage()
        const userSession = await userLogin(email, password, region, companyName, vat, phone, registration, zip, city, address, owners, boards);
        if (!userSession.error) {
            if (userSession.data) {
                setSession(userSession.data.data.session);
                localStorage.setItem('isSecondStep', JSON.stringify(true));
                const event = new Event('localStorageUpdate');
                window.dispatchEvent(event);
            }
        }
        else {
            if (userSession.error.response?.status === 409) {
                Swal.fire(optionsForAlertWithUserConflict).then(() => {
                    localStorage.setItem('isSecondStep', JSON.stringify(false));
                    const event = new Event('localStorageUpdate');
                    window.dispatchEvent(event);
                });
            }
            else {
                Swal.fire(optionsForAlertError).then(() => {
                    localStorage.setItem('isSecondStep', JSON.stringify(false));
                    const event = new Event('localStorageUpdate');
                    window.dispatchEvent(event);
                });
            }
        }
    }, [dispatch, navigate]);

    return { handleSignUp };
};
