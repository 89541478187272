import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem';
import {inputAddressStyleSM, inputProps, inputStyleSM} from "../../productReturns/ProductReturns";
import React, {useState} from "react";
import {NewWarrantyRequestTable} from "../newWarrantyRequestTable/NewWarrantyRequestTable";
import {useAppDispatch, useAppSelector} from "../../../hooks/ReduxHooks";
import {createBackendWarrantyRequest} from "../../../api/warranty";
import {Blured} from "../../blured/Blured";
import {resetWarrantyData} from "../../../store/slices/NewWarrantySlice";
import useMessage from "../../../hooks/UseMessage";
import {MESSAGE_VARIANT} from "../../../constants/consts";
import {Trans} from "react-i18next";
import {t} from "i18next";


export const NewWarrantyRequestSubmitForm = () => {

    const {message, showMessage, hideMessage} = useMessage()
    const dispatch = useAppDispatch();

    const {linesToWarranty} = useAppSelector(state => state.newWarranty)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleCreateReturn = async () => {
        if (!linesToWarranty.length) return
        setIsLoading(true)
        try {
            let createdDocs = []
            for (const el of linesToWarranty) {
                let formData = new FormData();
                if (el.filesData) {
                    el.filesData.forEach((file, index) => {
                        formData.append(`attachments`, file);
                    })
                }
                formData.append("id", el.lineId.toString());
                formData.append("quantity", el.quantity.toString());
                formData.append("state", "1");
                if (el.defectType) {
                    formData.append("defect_id", el.defectType.id.toString());
                }
                if (el.description) {
                    formData.append("comment", el?.description);
                }
                let result = await createBackendWarrantyRequest(formData)
                setIsLoading(false)
                if (result.error) return
                createdDocs.push(result?.data?.data?.request_id)
                showMessage({
                    variant: MESSAGE_VARIANT.WARNING,
                    text: t('created_requests', { count: createdDocs.length }),
                })
                dispatch(resetWarrantyData())
            }
        } catch (e) {
            setIsLoading(false)
        }
    }

    const handleSubmit = () => {
        return handleCreateReturn()
    }

    const deliveryAddress = [
        {
            id: 1,
            name: "Address 1"
        },
        {
            id: 2,
            name: "Address 2"
        }
    ]

    return (
        <>
            <Blured
                element={
                    <>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': {m: 1, width: '25ch'},
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <h5><Trans i18nKey="make_application"/></h5>
                            <TextField
                                id="outlined-basic"
                                size="small"
                                label={<Trans i18nKey="contact_person_label"/>}
                                variant="outlined"
                                inputProps={inputProps}
                                required
                                InputProps={{
                                    sx: {
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgb(110, 209, 243)',
                                            outline: 'none',
                                        },
                                    },
                                }}
                                sx={inputStyleSM}
                            />
                            <TextField
                                id="outlined-basic"
                                size="small"
                                label={<Trans i18nKey="phone_label"/>}
                                variant="outlined"
                                inputProps={inputProps}
                                required
                                InputProps={{
                                    sx: {
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgb(110, 209, 243)',
                                            outline: 'none',
                                        },
                                    },
                                }}
                                sx={inputStyleSM}
                            />
                            <TextField
                                id="outlined-select-currency"
                                select
                                size="small"
                                label={<Trans i18nKey="delivery_address_label"/>}
                                inputProps={inputProps}
                                defaultValue={-1}
                                required
                                InputProps={{
                                    sx: {
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgb(110, 209, 243)',
                                            outline: 'none',
                                        },
                                    },
                                }}
                                sx={inputAddressStyleSM}
                                className="delivery_address w-100"
                            >
                                <MenuItem
                                        key={`address_option_0`}
                                        value={-1}
                                > </MenuItem>
                                {deliveryAddress.map((address) => (
                                    <MenuItem
                                        key={`address_option_${address.id}`}
                                        value={address.id}
                                    >
                                        {address.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                id="outlined-basic"
                                size="small"
                                label={<Trans i18nKey="application_name_label"/>}
                                variant="outlined"
                                inputProps={inputProps}
                                required
                                InputProps={{
                                    sx: {
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgb(110, 209, 243)',
                                            outline: 'none',
                                        },
                                    },
                                }}
                                sx={inputStyleSM}
                            />
                        </Box>
                        <NewWarrantyRequestTable/>
                    </>
                }
                isLoading={isLoading}
            />
            <button
                type="button"
                className={`btn btn-search`}
                disabled={!linesToWarranty.length || isLoading}
                onClick={handleSubmit}
            >
                <Trans i18nKey="create_button"/>
            </button>
        </>

    )
}