import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import * as React from "react";
import {useAppSelector} from "../../hooks/ReduxHooks";
import {ROUTE_URLS} from "../../constants/consts";
import {t} from "i18next";

export const SEPARATOR = " / "
export const lastBreadcrumbStyles = {color: "#6ed1f3", cursor: "default", margin: "0 5px"};
export type BreadcrumbsType = Array<{ id: string; name: string }>;
// @ts-ignore
const DynamicCategoryBreadcrumb = ({match}) => {
    const categoryId = +match.params.categoryId;
    const hierarchyCategory = useSelector((state: any) => state.hierarchyCategory.data);
    const {product} = useAppSelector(state => state.product)

    const findCategoryBreadcrumbs = (
        categories: any,
        categoryId: number,
        breadcrumbs: BreadcrumbsType = []
    ): BreadcrumbsType | null => {
        if (categories && categories.length > 0) {
            for (const category of categories) {
                const newBreadcrumbs: BreadcrumbsType = [...breadcrumbs, {id: category.id, name: category.name}];

                if (category.id === categoryId) {
                    return newBreadcrumbs;
                }

                if (category.child_ids && category.child_ids.length > 0) {
                    const result = findCategoryBreadcrumbs(category.child_ids, categoryId, newBreadcrumbs);
                    if (result) return result;
                }
            }
        }


        return null;
    };

    const categoryBreadcrumbs = findCategoryBreadcrumbs(hierarchyCategory, categoryId);
    const lastCategoryBreadcrumbId = categoryBreadcrumbs?.[categoryBreadcrumbs?.length - 1]?.id;

    return (
        <>
            {categoryBreadcrumbs?.map((category: any) => (
                <NavLink key={category?.name} to={`/products/${category?.id}`}
                         style={({isActive}) => ({
                             color: isActive && !product ? '#808084' : '#6ed1f3',
                             textDecoration: 'none',
                             cursor: isActive && !product ? "default" : "pointer",

                         })}>
                    {category?.name}
                    {lastCategoryBreadcrumbId !== category?.id &&
                        <span style={lastBreadcrumbStyles}><b>{SEPARATOR}</b></span>}
                </NavLink>
            ))}
        </>
    );
};

const BaseBreadcrumbNode = (props: {value: string | undefined, isActive: boolean}) => {
    const {value, isActive} = props

    const handleClick = (e: { preventDefault: () => void; }) => {
        if (!isActive) e.preventDefault()
    }

    return (
        <NavLink key={value} to="" onClick={handleClick}

                 style={() => ({
                     color: isActive ? '#6ed1f3' : '#808084' ,
                     textDecoration: 'none',
                     cursor: isActive ? "pointer" : "default",
                 })}>
            {value}
        </NavLink>
    );
};

const DynamicCategoryProductBreadcrumb = () => {
    const {product} = useAppSelector(state => state.product)
    return <BaseBreadcrumbNode value={product?.name} isActive={false}/>
};

const DynamicOrderBreadcrumb = () => {
    const {orderData} = useAppSelector(state => state.order)

    return <BaseBreadcrumbNode value={orderData?.name} isActive={false}/>
};

const DynamicReturnBreadcrumb = () => {
    const {returnData} = useAppSelector(state => state.return)
    return <BaseBreadcrumbNode value={returnData?.name} isActive={false}/>
};
const DynamicWarrantyBreadcrumb = () => {
    const {warrantyRequestData} = useAppSelector(state => state.warrantyRequest)
    return <BaseBreadcrumbNode value={warrantyRequestData?.name} isActive={false}/>
}

const DynamicCreditLimitBreadcrumb = () => {
    const {creditLimitData} = useAppSelector(state => state.creditLimit)
    return <BaseBreadcrumbNode value={creditLimitData?.name} isActive={false}/>
}

export const breadcrumbsRoutes = [
    { path: ROUTE_URLS.HOME, breadcrumb: t("breadcrumb.home") },
    { path: ROUTE_URLS.CATALOG, breadcrumb: t("breadcrumb.catalog") },
    { path: ROUTE_URLS.QUESTIONARY, breadcrumb: t("breadcrumb.questionary") },
    { path: ROUTE_URLS.CATALOG_CATEGORY, breadcrumb: DynamicCategoryBreadcrumb },
    { path: ROUTE_URLS.PRODUCT, breadcrumb: "" },
    { path: ROUTE_URLS.PRODUCT_FROM_CATEGORY, breadcrumb: DynamicCategoryProductBreadcrumb },
    { path: ROUTE_URLS.CART, breadcrumb: t("breadcrumb.cart") },
    { path: ROUTE_URLS.ORDERS, breadcrumb: t("breadcrumb.orders") },
    { path: ROUTE_URLS.ORDER, breadcrumb: DynamicOrderBreadcrumb },
    { path: ROUTE_URLS.FIN_TERMS, breadcrumb: t("breadcrumb.finances") },
    { path: ROUTE_URLS.RETURNS, breadcrumb: t("breadcrumb.returns") },
    { path: ROUTE_URLS.RETURN, breadcrumb: DynamicReturnBreadcrumb },
    { path: ROUTE_URLS.WARRANTY, breadcrumb: DynamicWarrantyBreadcrumb },
    { path: ROUTE_URLS.CREDIT_LIMIT, breadcrumb: DynamicCreditLimitBreadcrumb },
];
