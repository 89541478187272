import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {useAppSelector} from "../../../hooks/ReduxHooks";
import {Link} from "react-router-dom";
import {MESSAGE_VARIANT, ORDER_TYPES, ROUTE_URLS} from "../../../constants/consts";
import {t} from "i18next";
import {FormattedValue} from "../../formattedValue/FormattedValue";
import Checkbox from "@mui/material/Checkbox";
import {checkboxStyleSM} from "../../catalog/catalogControl/style";
import {SaleOrdersPaginatedLinesType} from "../../catalog/types";
import {MessageContext} from "../../../hooks/UseMessage";
import {OrderLinesContext} from "../../../hooks/UseOrderLinesSelectContext";
import {Trans} from "react-i18next";
import {Blured} from "../../blured/Blured";
import {deleteOrderLines, updateOrderLines} from "../../../api/orders";
import {createBackendSaleOrder} from "../../../api/productCart";
import {BasePagination} from "../../pagination/Pagination";
import {StyledCheckbox, StyledFormControlLabel} from "../../../pages/ordersPage/OrdersPage";
import {ProcessOrderModal} from "../../processOrderModal/ProcessOrderModal";
import UseInactiveOrders from "../../../hooks/UseInactiveOrders";

interface TableRowProps {
    orderLine: SaleOrdersPaginatedLinesType,
    orderLines: SaleOrdersPaginatedLinesType[],
    onCheckboxChange: (id: number, checked: any) => void,
    type?: ORDER_TYPES;
}

interface OrdersLinesContentProps {
    type?: ORDER_TYPES;
    setType?: React.Dispatch<React.SetStateAction<ORDER_TYPES>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    fetchOrdersLines: () => void;
    onlyMyDocuments: boolean,
    setOnlyMyDocuments: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OrderLinesContentRow = (props: TableRowProps) => {

    const {
        orderLine,
        orderLines,
        type
    } = props
    const [toOrder, setToOrder] = useState<number | "">(orderLine.product_uom_qty)
    const { selectedLines, setSelectedLines, setLines} = useContext(OrderLinesContext)
    const [isChecked, setIsChecked] = useState(selectedLines.includes(orderLine.id))

    const orderURL = `${ROUTE_URLS.ORDERS}/${orderLine.order_id.id}`
    const productURL = `${ROUTE_URLS.PRODUCTS}/${orderLine.product_id.id}`

    const {showMessage} = useContext(MessageContext)

    useEffect(() => {
        setToOrder(orderLine.product_uom_qty)
    }, [orderLine.product_uom_qty]);

    const handleChangeToOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (!value) {
            setToOrder("")
            return;
        }
        const numValue = Number(value)
        if (isNaN(numValue)) return
        if (numValue >= 0 && numValue <= orderLine.product_uom_qty) {
            setToOrder(numValue)
            setLines(orderLines.map(row => (row.id === orderLine.id ? { ...row,  toOrder: numValue} : row)))
        } else {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: t("invalidQuantity", { value })
            })
        }
    }

    useEffect(() => {
        setIsChecked(selectedLines.includes(orderLine.id))
    }, [selectedLines]);

    const handleLineCheck = () => {
        if (selectedLines.includes(orderLine.id)) {
            setSelectedLines(selectedLines.filter((item) => item !== orderLine.id));
        } else {
            setSelectedLines([...selectedLines, orderLine.id]);
        }
        props.onCheckboxChange(orderLine.id, !isChecked);
    }

    return (
        <tr>
            <td scope="row">
                <Checkbox
                    checked={isChecked}
                    sx={checkboxStyleSM}
                    value={""}
                    onChange={handleLineCheck}
                />
            </td>
            <td className="text-start">
                <Link className="catalog_product__link" to={productURL}>
                    <span className="text-start fw-bold">{orderLine.product_id.name}</span>
                </Link>
            </td>
            <td className="text-center">
                <span className="text-center">{orderLine.requested_qty}</span>
            </td>
            <td className="text-center">
                <span className="text-center">{orderLine.product_uom_qty}</span>
            </td>
            {type === ORDER_TYPES.RESERVE && (
                <td className="text-start">
                    <input
                        className="to_order border border-secondary rounded"
                        type={"text"}
                        value={toOrder}
                        onChange={handleChangeToOrder}/>
                </td>
            )}

            <td className="text-start">
                <FormattedValue value={orderLine.price_unit}/> {orderLine?.currency_id?.symbol}
            </td>
            <td className="text-start">
                <FormattedValue
                    value={orderLine.price_total}/> {orderLine?.currency_id?.symbol}
            </td>
            <td className="text-start">
                <span className="text-start">{orderLine?.order_id?.responsible_id?.name}</span>
            </td>

            {type === ORDER_TYPES.RESERVE && (
                <>
                    <td className="text-start">
                        <span className="text-start">{orderLine?.order_id?.create_date.split(" ")[0]}</span>
                    </td>
                    <td className="text-start">
                        <span className="text-start">{orderLine?.order_id?.reservation_date}</span>
                    </td>
                </>

            )}
            {type === ORDER_TYPES.PRE_ORDER && (
                <>
                    <td className="text-start">
                        <span className="text-start">{orderLine?.order_id?.delivery_status}</span>
                    </td>
                    <td className="text-start">
                        <span className="text-start">{orderLine?.order_id?.date_order?.split(" ")[0]}</span>
                    </td>
                    <td className="text-start">
                        <span className="text-start">{orderLine?.pdp_warehouse_date?.split(" ")[0] || ""}</span>
                    </td>
                </>

            )}

            <td className="text-start">
                <Link className="catalog_product__link" to={orderURL}>
                    <span className="text-start fw-bold">{orderLine.order_id.name}</span>
                </Link>
            </td>
        </tr>
    )

}


export const OrderLinesContent = (props: OrdersLinesContentProps) => {

    const {
        type,
        setType,
        isLoading,
        setIsLoading,
        fetchOrdersLines,
        onlyMyDocuments,
        setOnlyMyDocuments,
    } = props
    const {linesData} = useAppSelector(state => state.reservationLines)
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false)
    const [selectedLines, setSelectedLines] = useState<number[]>([])
    const [lines, setLines] = useState<SaleOrdersPaginatedLinesType[]>([])
    const [isOrderModalOpen, setOrderModalOpen] = useState(false);
    const inactiveOrders = UseInactiveOrders()
    const {showMessage} = useContext(MessageContext)

    const handleCloseModal = (isOrder: boolean = false) => {
        setOrderModalOpen(false)
    };

    const ToggleLock = () => {
        if (!selectedLines || selectedLines.length === 0 ) {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: t("noLinesSelected")
            })
            return
        }
        setOrderModalOpen(true)
    };

    const handleApplyModal = async (selectedOrderId?: number,) => {
        return selectedOrderId ? addTo(selectedOrderId) : makeOrder()
    };

    const updateReservationLines = async (linesForUpdate: SaleOrdersPaginatedLinesType[]) => {
        if (!linesData || linesForUpdate.length === 0) return
        return await updateOrderLines({
            "id_order": 0,
            "line_data": linesForUpdate.map(el => {
                let lineData: { id: number, quantity: number, requested_qty?: number } = {
                    "id": el.id,
                    "quantity": (el.product_uom_qty - (el.toOrder || el.product_uom_qty))
                }

                return lineData
            })
        })

    }

    const releaseReserve = async () => {
        if (!linesData || selectedLines.length === 0) return
        const linesToDelete = lines.filter(el => selectedLines.includes(el.id) && (!el.toOrder || el.toOrder === el.product_uom_qty)).map(el => el.id)
        let linesToUpdate = lines.filter(el => selectedLines.includes(el.id) && (!linesToDelete.includes(el.id)) && ((el.toOrder || el.product_uom_qty) > 0))
        if ((!linesToUpdate || linesToUpdate?.length === 0) && (!linesToDelete || linesToDelete?.length === 0)) return
        setIsLoading(true)
        const deleteResult = await deleteOrderLines({"ids": linesToDelete})
        if (deleteResult && deleteResult.error) {
            linesToUpdate.push(...lines.filter(el => linesToDelete.includes(el.id)))
        }
        await updateReservationLines(linesToUpdate)
        fetchOrdersLines()
        setSelectedLines([])
        setIsLoading(false)
    }

    const getLinesToOrder = () => {
        let linesForOrder: SaleOrdersPaginatedLinesType[] = []
        if (selectedLines.length === 0) {
            linesForOrder = lines
        } else {
            linesForOrder = lines.filter(el => selectedLines.includes(el.id) && ((el.toOrder || el.product_uom_qty) > 0))
        }
        return linesForOrder
    }

    const processOrderedLines = async (linesForOrder: SaleOrdersPaginatedLinesType[]) => {
        const linesToDelete = linesForOrder.filter(el => (!el.toOrder || el.toOrder === el.product_uom_qty)).map(el => el.id)
        let linesToUpdate = linesForOrder.filter(el => !linesToDelete.includes(el.id))
        if (linesToDelete) {
            const deleteResult = await deleteOrderLines({"ids": linesToDelete})
            if (deleteResult && deleteResult.error) {
                linesToUpdate.push(...linesForOrder.filter(el => linesToDelete.includes(el.id)))
            }
        }
        if (linesToUpdate) {
            await updateReservationLines(linesToUpdate)
        }

        fetchOrdersLines()
        setSelectedLines([])
        setIsLoading(false);
    }

    const addTo = async (orderId: number) => {
        if (!orderId || !linesData) {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: t("invalidOrderNumber")
            });
            setIsLoading(false);
            return;
        }
        let linesForOrder = getLinesToOrder()
        const orderData = {
            id_order: orderId,
            line_data: linesForOrder.map(el => ({
                product_id: el.product_id.id,
                quantity: el.toOrder || el.product_uom_qty,
            }))
        };
        let result = await updateOrderLines(orderData);
        if (result?.error) {
            setIsLoading(false);
            return;
        }
        await processOrderedLines(linesForOrder)
        showMessage({
            variant: MESSAGE_VARIANT.SUCCESS,
            text: t("addedToOrder")
        });
    };

    const makeOrder = async () => {
        if (!linesData) return
        let linesForOrder = getLinesToOrder()
        if (!linesForOrder) return
        const newSoData = {
            "is_reserve": false,
            "is_pre_order": false,
            "responsible":localStorage.getItem("companyName"),
            "is_active": false,
            "order_line": linesForOrder.map((el) => {
                return ({
                        "product_id": el.product_id.id,
                        "product_uom_qty": el.toOrder || el.product_uom_qty,
                    }
                )
            })
        }
        setIsLoading(true)
        let result = await createBackendSaleOrder(newSoData)
        if (result?.error) {
            setIsLoading(false);
            return;
        }
        await processOrderedLines(linesForOrder)
        setIsLoading(false)
    }


    useEffect(() => {
        if (!linesData) return
        setLines(linesData.lines.map(el => {
            return {...el, toOrder: el.product_uom_qty}
        }))

        return () => {setLines([])}
    }, [linesData]);

    useEffect(() => {
        if (lines && lines.length > 0) {
            const allChecked = lines.every(line => selectedLines.includes(line.id));
            setIsAllChecked(allChecked);
        }
    }, [lines, selectedLines]);

    const handleChildCheckboxChange = (id: number, checked: any) => {
        const updatedSelectedLines = checked
            ? [...selectedLines, id]
            : selectedLines.filter((lineId) => lineId !== id);

        setSelectedLines(updatedSelectedLines);

        const allChecked = updatedSelectedLines.length === lines.length;
        setIsAllChecked(allChecked);
    };

    const handleSelectAll = (e: { target: { checked: any; }; }) => {
        if (!linesData) return;
        const checked = e.target.checked;
        setIsAllChecked(checked);

        if (checked) {
            setSelectedLines(linesData.lines.map(el => el.id));
        } else {
            setSelectedLines([]);
        }
    };

    useEffect(() => {
        if (setType && type) {
            setType(type)
        }
    }, [setType, type])

    return (
        <OrderLinesContext.Provider value={{selectedLines, setSelectedLines, lines, setLines, setIsLoading}}>
            <div style={{marginBottom: "10px"}} className="d-flex gap-4 flex-wrap">
                {type === ORDER_TYPES.RESERVE && (
                    <>
                        <ProcessOrderModal
                                isOpen={isOrderModalOpen}
                                onClose={() => handleCloseModal(true)}
                                onApply={handleApplyModal}
                                inactiveOrders={inactiveOrders}
                                isCart={true}

                        />
                        <button type="button" className="btn-main btn" onClick={ToggleLock}
                                disabled={selectedLines.length === 0}>
                            <Trans i18nKey="order"/>
                        </button>
                        <button type="button" className="btn-main btn" onClick={releaseReserve}
                                disabled={selectedLines.length === 0}>
                            <Trans i18nKey="release_reserve"/>
                        </button>
                    </>
                )}

                {type === ORDER_TYPES.PRE_ORDER && (
                    <>
                    </>
                )}

            </div>
            <StyledFormControlLabel
                control={
                    <StyledCheckbox
                        checked={onlyMyDocuments}
                        onChange={(e) => setOnlyMyDocuments(e.target.checked)}
                    />
                }
                disabled={isLoading}
                label={t("only_my_documents")}
            />
            <Blured
                element={
                    <div className="ordersTableWrapper">
                    <table className="table returnTable">
                        <thead className="returnTable">
                        <tr>
                            <th scope="row">
                                <Checkbox
                                    checked={isAllChecked}
                                    sx={checkboxStyleSM}
                                    value={""}
                                    onChange={handleSelectAll}
                                />
                            </th>
                            <th scope="col" className="text-colored text-start text-nowrap">
                                {t('product')}
                            </th>
                            <th scope="col" className="text-colored text-start text-nowrap">
                                {t('quantity_ordered_sht')}
                            </th>
                            <th scope="col" className="text-colored text-start text-nowrap">
                                {t('quantity_confirmed_sht')}
                            </th>
                            {type === ORDER_TYPES.RESERVE && (
                                <th scope="col" className="text-colored text-start text-nowrap">
                                    {t('order')}
                                </th>
                            )}

                            <th scope="col" className="text-colored text-start text-nowrap">
                                {t('price')}
                            </th>
                            <th scope="col" className="text-colored text-start text-nowrap">
                                {t('amount')}
                            </th>
                            <th scope="col" className="text-colored text-start text-nowrap">
                                {t('responsible')}
                            </th>
                            {type === ORDER_TYPES.RESERVE && (
                                <>
                                    <th scope="col" className="text-colored text-start text-nowrap">
                                        <Trans i18nKey="reserve_date" components={{br: <br/>}}/>
                                    </th>
                                    <th scope="col" className="text-colored text-start text-nowrap">
                                        {t('reservation_expiry')}
                                    </th>
                                </>

                            )}
                            {type === ORDER_TYPES.PRE_ORDER && (
                                <>
                                    <th scope="col" className="text-colored text-start text-nowrap">
                                        <Trans i18nKey="status"/>
                                    </th>
                                    <th scope="col" className="text-colored text-start text-nowrap">
                                        <Trans i18nKey="order_date" components={{br: <br/>}}/>
                                    </th>
                                    <th scope="col" className="text-colored text-start text-nowrap">
                                        <Trans i18nKey="dpd_to_warehouse" components={{br: <br/>}}/>
                                    </th>
                                </>
                            )}

                            <th scope="col" className="text-colored text-start text-nowrap">
                                {type === ORDER_TYPES.RESERVE ? t('reserve_num') : t('preorder_num')}
                            </th>

                        </tr>
                        </thead>
                        <tbody className="returnTable__body">
                        {lines && lines.map((el) => {
                                return (
                                    <OrderLinesContentRow
                                        orderLine={el}
                                        orderLines={lines}
                                        onCheckboxChange={handleChildCheckboxChange}
                                        type={type}
                                    />
                                )
                            }
                        )}
                        </tbody>
                    </table>
                    {linesData && (
                        <div>
                            {linesData.pages_count > 1 && <BasePagination count={linesData.pages_count}/>}
                        </div>
                    )}
                </div>
                }
                isLoading={isLoading}
            />
        </OrderLinesContext.Provider>
    )
}