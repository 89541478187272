import React, {useState} from "react";
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import useDebounce from "../../../../hooks/UseDebounce";
import {fetchUpdateOrder} from "../../../../api/orders";
import {Trans} from "react-i18next";
import {useDidUpdate} from "../../../../hooks/UseDidUpdate";

export const OrderIntroduction = () => {

    const {orderData} = useAppSelector(state => state.order)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [name, setName] = useState<string>(orderData?.reservation_name || "")
    const debouncedName = useDebounce(name, 700)

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    useDidUpdate(() => {
        if (!orderData) return

        (async () => {
            setIsLoading(true)
            await fetchUpdateOrder( orderData.id,{
                "reservation_name": debouncedName,
            })
            setIsLoading(false)
        })()

    }, [debouncedName]);

    return (
        <>
            <div className="border-bottom-0 pt-0 pb-3">
                <div className="d-flex bold-text justify-content-between gap-2">
                    {orderData?.is_reserve ? (
                        <>
                            <h3>
                                <Trans i18nKey="reserve_label"/>: <span className="text-colored">{orderData && orderData.name}</span>
                            </h3>
                        </>
                    ) : (
                        <>
                            <div>
                                <h3>
                                     <Trans i18nKey={orderData?.is_pre_order ? "preorder_label" : "order_label"}/>: <span className="text-colored">{orderData && orderData.name}</span>
                                </h3>
                                {orderData?.is_b2b_active ? (
                                    <h5 className="text-success"><Trans i18nKey="active"/></h5>
                                ) : (
                                    <h5 className="text-danger"><Trans i18nKey="inactive"/></h5>
                                )}
                            </div>
                            {orderData?.delivery_status && (
                                <div>
                                    Статус: <span
                                    className="text-success">{orderData.delivery_status}</span>
                                </div>
                            )}

                        </>
                    )}
                </div>
                {orderData?.is_reserve && (
                    <div className="d-flex justify-content-start gap-2 w-50">
                        <label className="fw-bold"><Trans i18nKey="name"/>:</label>
                        <input type="text" disabled={isLoading} onBlur={handleChangeName} className="form-control"
                               defaultValue={name}/>
                    </div>
                )}
            </div>
        </>
    )
}