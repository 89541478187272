import React, {memo, useContext, useEffect} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {MESSAGE_VARIANT} from "../../constants/consts";
import {MessageContext} from "../../hooks/UseMessage";
import {t} from "i18next";
import {Trans} from "react-i18next";

interface ProcessOrderModalProps {
    isOpen: boolean;
    onClose: () => void;
    onApply: (selectedOrderId?: number) => void;
    isCart?: boolean;
    inactiveOrders?: any[]
}

export const ProcessOrderModal = memo((props: ProcessOrderModalProps) => {

    const { isOpen, onClose, onApply , isCart, inactiveOrders} = props
    const [selectedOrderId, setSelectedOrderId] = React.useState<number | undefined>(undefined);
    const [selectedOption, setSelectedOption] = React.useState<"new" | "existing">("new");
    const {showMessage} = useContext(MessageContext)

    const handleApply = () => {
        if (((isCart && selectedOption === "existing") || !isCart) && !selectedOrderId) {
            showMessage({
                variant: MESSAGE_VARIANT.WARNING,
                text: t("invalidOrderNumber")
            })
            return
        }
        onApply(selectedOrderId);
        onClose();
    };

    useEffect(() => {
        setSelectedOrderId(undefined)
    }, [selectedOption])

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4
                }}
            >
                <FormControl component="fieldset" className="w-100">
                    <FormLabel component="legend">
                        {isCart ? <Trans i18nKey="choose_action"/> : <Trans i18nKey="existingOrder"/>}
                    </FormLabel>
                    {isCart && (
                        <RadioGroup
                            aria-label="reserve"
                            name="reserve"
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value as 'new' | 'existing')}
                        >
                            <FormControlLabel
                                value="new"
                                control={<Radio sx={{ color: 'rgb(110, 209, 243)', '&.Mui-checked': { color: 'rgb(110, 209, 243)' } }} />}
                                label={isCart ? "Новый заказ" : "Корзина"}
                            />
                            <FormControlLabel
                                value="existing"
                                control={<Radio sx={{ color: 'rgb(110, 209, 243)', '&.Mui-checked': { color: 'rgb(110, 209, 243)' } }} />}
                                label="Текущий заказ"
                            />
                        </RadioGroup>

                    )}
                    {(selectedOption === "existing" || !isCart) && (
                        <Select
                            value={selectedOrderId}
                            onChange={(e) => setSelectedOrderId(Number(e.target.value))}
                            displayEmpty
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            <MenuItem value="" disabled>
                                <Trans i18nKey="selectOrder"/>
                            </MenuItem>
                            {inactiveOrders && inactiveOrders.map((order) => (
                                <MenuItem key={order.id} value={order.id}>
                                    {order.number}
                                </MenuItem>
                            ))}
                        </Select>
                    )}

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', gap: "5px"}}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'rgb(110, 209, 243)',
                                '&:hover': { backgroundColor: 'rgb(90, 180, 210)' }
                            }}
                            onClick={handleApply}
                        >
                            <Trans i18nKey="apply" />
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor: 'rgb(110, 209, 243)',
                                color: 'rgb(110, 209, 243)',
                                '&:hover': {
                                    borderColor: 'rgb(90, 180, 210)',
                                    color: 'rgb(90, 180, 210)'
                                }
                            }}
                            onClick={onClose}
                        >
                            <Trans i18nKey="cancel" />
                        </Button>
                    </Box>
                </FormControl>
            </Box>
        </Modal>
    );
});
