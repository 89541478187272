import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { CartTableTr } from "./cartTableTr";
import "./CartTable.sass";
import { useAppSelector } from "../../hooks/ReduxHooks";
import { t } from "i18next";

interface CartTableProps {
    cartProductLines: any;
    changeProductCountCart: any;
    setTotalCartBillInfo: any;
    clearCartLines: any;
}

interface TableRef {
    getActiveProductLines: () => any;
    setIsAllCheckBoxChecked: (newState: any) => void;
    setCheckedItems: (newState: any) => void;
}

export const CartTable = forwardRef<TableRef, CartTableProps>((props, ref) => {
    const [checkedItems, setCheckedItems] = useState<any>([]);
    const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false);
    const { activeCartData } = useAppSelector((state) => state.activeCart);


    useEffect(() => {
        setIsAllCheckBoxChecked(true);
        setCheckedItems(activeCartData?.cart_lines.map((el) => el.id));
        props.changeProductCountCart(activeCartData?.cart_lines.length);
    }, []);

    useImperativeHandle(ref, () => ({
        getActiveProductLines() {
            return checkedItems;
        },
        setIsAllCheckBoxChecked: (newState: any) => setIsAllCheckBoxChecked(newState),
        setCheckedItems: (newState: any) => setCheckedItems(newState),
    }));

    const clearCartLines = (linesIds: Array<number>) => {
        props.clearCartLines(linesIds);
        handleCheckboxChange(linesIds[0], true);
    };

    const handleHeaderCheckBoxChange = () => {
        if (!activeCartData) return;
        if (isAllCheckBoxChecked) {
            setCheckedItems([]);
            props.changeProductCountCart(0);
        } else {
            setCheckedItems(activeCartData.cart_lines.map((item: any) => item.id));
            props.changeProductCountCart(activeCartData.cart_lines.length);
        }
        setIsAllCheckBoxChecked(!isAllCheckBoxChecked);
    };

    const handleCheckboxChange = (id: number, isDelete = false) => {
        if (!activeCartData) return;
        let newCheckedItems;
        let productCartLen = activeCartData.cart_lines.length;
        if (isDelete && isChecked(id)) {
            productCartLen -= 1;
        }
        if (checkedItems.includes(id)) {
            newCheckedItems = checkedItems.filter((itemId: any) => itemId !== id);
        } else {
            if (isDelete) {
                productCartLen -= 1;
                newCheckedItems = [...checkedItems];
            } else {
                newCheckedItems = [...checkedItems, id];
            }
        }
        setCheckedItems(newCheckedItems);
        if (newCheckedItems && newCheckedItems.length > 0) {
            setIsAllCheckBoxChecked(newCheckedItems.length === productCartLen);
            props.changeProductCountCart(newCheckedItems.length);
        } else {
            setIsAllCheckBoxChecked(false);
            props.changeProductCountCart(0);
        }
    };

    const isChecked = (id: any) => checkedItems.includes(id);

    const sortCartLines = (cartLines: any) => {
        return [...cartLines].sort((prev, next) => prev.sequence - next.sequence);
    };

    return (
        <table className="table cartTable">
            <thead className="cartTable__header">
            <tr>
                <th scope="col">
                    <input
                        checked={isAllCheckBoxChecked}
                        type="checkbox"
                        id="AllCartProductsCheck"
                        onChange={handleHeaderCheckBoxChange}
                    />
                </th>
                <th scope="col" className="text-colored text-center text-nowrap">
                    {t("table_header_number")}
                </th>
                <th scope="col" className="text-colored text-center text-nowrap">
                    {t("table_header_photo")}
                </th>
                <th scope="col" className="text-colored text-start text-nowrap">
                    {t("table_header_code")}
                </th>
                <th scope="col" className="text-colored text-start text-nowrap">
                    {t("table_header_manufacturer_code")}
                </th>
                <th scope="col" className="text-colored text-start text-nowrap"></th>
                <th scope="col" className="text-colored text-start text-nowrap">
                    {t("table_header_product_name")}
                </th>
                <th scope="col" className="text-colored text-start text-nowrap">
                    {t("table_header_quantity")}
                </th>
                <th
                    scope="col"
                    className="text-colored text-start text-nowrap"
                    dangerouslySetInnerHTML={{
                        __html: t("table_header_price_without_vat") + ` ${activeCartData?.currency?.symbol}`,
                    }}
                ></th>
                <th
                    scope="col"
                    className="text-colored text-start text-nowrap"
                    dangerouslySetInnerHTML={{
                        __html: t("table_header_total_with_vat") + ` ${activeCartData?.currency?.symbol}`,
                    }}
                ></th>
                <th scope="col" className="text-colored text-start text-nowrap"></th>
            </tr>
            </thead>
            <tbody className="cartTable__body">
            {activeCartData &&
                sortCartLines(activeCartData.cart_lines).map((product: any, index: number) => (
                    <CartTableTr
                        key={product.id}
                        clearCartLines={clearCartLines}
                        setTotalCartBillInfo={props.setTotalCartBillInfo}
                        indexLine={index}
                        checked={isChecked(product.id)}
                        productData={product}
                        onCheckboxChange={handleCheckboxChange}
                    />
                ))}
            </tbody>
        </table>
    );
});
