import {CatalogControl} from "../../catalogControl/CatalogControl";
import {useAppSelector} from "../../../../hooks/ReduxHooks";
import {Trans} from "react-i18next";
import React from "react";
import {SortControl} from "../../catalogControl/sortCatalogContent/SortCatalogContent";

interface CatalogTableProps {
    isSimple?: boolean
}

export const TableHeader = (props: CatalogTableProps) => {


    const handleSortChange = (sortType: string) => {}

        const {activeDisplayFields} = useAppSelector(state => state.page)
    return (
        <tr className="align-baseline catalog-table">
            {activeDisplayFields.includes("image") && (
                <th style={{width: "7%"}} scope="col" className="text-dark text-start text-nowrap">
                    <Trans i18nKey="image"/>
                </th>
            )}
            {activeDisplayFields.includes("default_code") && (
                <th style={{width: "5%"}} scope="col" className="text-colored text-start text-nowrap">
                    <Trans i18nKey="code"/>
                </th>
            )}
            {activeDisplayFields.includes("brand") && (
                <th style={{width: "5%"}} scope="col" className="text-colored text-center text-nowrap">
                    <Trans i18nKey="brand"/>
                </th>
            )}
            {activeDisplayFields.includes("product_code") && (
                <th style={{width: "10%"}} scope="col" className="text-colored text-start text-nowrap product__code">
                    <Trans i18nKey="manufacturer_code"/>
                </th>
            )}
            <th style={{width: "3%"}} scope="col" className="text-colored text-start text-nowrap"></th>
            <th style={{width: "46%"}} scope="col" className="text-colored text-start text-nowrap product__name">
                <Trans i18nKey="product_name"/>
            </th>
            <th style={{width: "7%"}} scope="col" className="text-colored text-center text-nowrap">
                <Trans i18nKey="availability"/>
            </th>
            <th style={{width: "5%"}} scope="col" className="text-colored text-center text-nowrap">
                <Trans i18nKey="price"/>
            </th>
            <th style={{width: "10%"}} scope="col" className="text-colored text-start text-nowrap">
                {!props.isSimple && (
                    <div className="d-flex justify-content-end" style={{gap: "5px"}}>
                        <SortControl onSortChange={handleSortChange}/>
                        <CatalogControl/>
                    </div>
                )}
            </th>
        </tr>

    )
}
