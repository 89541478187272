import useSWR from "swr";
import {fetchOrdersRequests} from "../api/orders";
import {useEffect, useState} from "react";

export const UseInactiveOrders = () => {

    const [inactiveOrders, setInactiveOrders] = useState<any[]>([])

    const {data} = useSWR(
        "inactive_orders",
        () => fetchOrdersRequests(
            {
                pageNum: 1,
                isReserve: false,
                isActive: false,
            }
    ))

    useEffect(() => {
        setInactiveOrders((data?.data?.data?.sale_info || []).map((el: { id: any; name: any;}) => {
            return {
                id: el.id,
                number: el.name,
            }
        }))
    }, [data]);

    return inactiveOrders

}

export default UseInactiveOrders

