import { SignIn } from "../../components/auth/SignIn";
import {AuthContainer, AuthContainerChild} from "../../components/auth/styles";
import {Trans} from "react-i18next";
export const SignInPage = () => {





    return (
        <AuthContainer>
            <AuthContainerChild>
                <h1>
                    <Trans i18nKey="signIn"/>
                </h1>
                <SignIn/>
            </AuthContainerChild>
        </AuthContainer>
    )
}
