import React from "react";
import { Form } from "./Form";
import { useSignUp } from "../../hooks/UseSignUp.hook";
import { useTranslation } from "react-i18next";

export const SignUp: React.FC = () => {
    const { handleSignUp } = useSignUp();
    const { t } = useTranslation();

    return (
        <Form isSignup title={t("signUpTitle")} handleClick={handleSignUp} />
    );
};
