import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchOrderLinesPaginated} from "../../api/orders";
import {SaleOrdersPaginatedDataType} from "../../components/catalog/types";

export type initialStateType = {
    isLinesLoading: boolean;
    linesData: SaleOrdersPaginatedDataType | undefined
}

export type fetchOrdersLinesParamsType = {
    pageNum: number,
    isReserve: boolean,
    isPreOrder?: boolean,
    isOwn?: boolean,
    searchValue?: string,
    activeFilters?: any,
    operator?: string,
}

const initialState: initialStateType = {
    linesData: undefined,
    isLinesLoading: true,
}


export const fetchLines =
    createAsyncThunk("slice/fetchLines",
        async (values: fetchOrdersLinesParamsType) => {
            const result = await fetchOrderLinesPaginated(values)
            return result.data?.data
        })
export const resetLines =
    createAsyncThunk("slice/createAsyncThunk",
        async () => initialState
    )

const reservationLinesSliceReducer = createSlice({
    name: "reservationLines",
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchLines.fulfilled, (state, action) => {
            state.linesData = action.payload
            state.isLinesLoading = false
        }).addCase(resetLines.fulfilled, (state, action) => {
            Object.assign(state, initialState);
            state.isLinesLoading = false;
        }).addCase(fetchLines.pending, (state, action) => {
            state.isLinesLoading = true;
        })
    }
})

export default reservationLinesSliceReducer.reducer